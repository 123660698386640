import React from 'react'
import './hero.scss'
import CTA from '../../../common/Buttons/cta'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const navigate = useNavigate()
  // const [loadCount, setLoadCount] = useState(0)

  // const setVideoLoaded = () => {
  //   if (loadCount <= 1) {
  //     setLoadCount(loadCount + 1)
  //   }
  // }
  return (
    <div className='relative hero-wrapper'>
      {/* <div className='video-wrapper'>
        <video
          className='bg-index'
          playsInline
          autoPlay
          muted
          loop
          src='https://ipfs.io/ipfs/QmPjbfZKBCk4HpYnkuLsKkoexg5SjpRaLzuiHZaGv3pPrN'
          data-src='https://ipfs.io/ipfs/QmPjbfZKBCk4HpYnkuLsKkoexg5SjpRaLzuiHZaGv3pPrN'
          // src='https://pinata.thena.fi/ipfs/QmPjbfZKBCk4HpYnkuLsKkoexg5SjpRaLzuiHZaGv3pPrN'
          // data-src='https://pinata.thena.fi/ipfs/QmPjbfZKBCk4HpYnkuLsKkoexg5SjpRaLzuiHZaGv3pPrN'
        />
      </div> */}
      <div className='container-4 mx-auto hero-box'>
        <div className='why-img-item'>
          <div className='why-img-item-body f-f-fg'>
            <h2 className='heading'>The Native Liquidity Layer Of Linea.</h2>
            <CTA
              icon={true}
              title={'SWAP NOW'}
              onClickHandler={() => {
                navigate('/swap')
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className='hero-wrapper'>
        <div>
          <p className='hero-header'>The Native Liquidity Layer Of Linea°</p>
          <p className='hero-text'>
            Step into the heart of Linea's intrinsic on-chain liquidity marketplace. Driven by state-of-the-art DEX infrastructure, we present an exceptionally
            efficient DeFi solution. Immerse yourself in the capabilities of Lynex now!
          </p>
          <div className='hero-actions'>
            <CTA
              icon={true}
              className='max-w-[250px]'
              title={'SWAP NOW'}
              onClickHandler={() => {
                navigate('/swap')
              }}
            />
          </div>
        </div>
        <img className='hero-image' src='/images/home/hero.png' alt='hero image' />
      </div> */}
    </div>
  )
}

export default Index
