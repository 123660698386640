import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import TabFilter from '../../components/common/TabFilter'
import SearchInput from '../../components/common/Input/SearchInput'
import Table from '../../components/pages/vote/table'
import Timer from '../../components/common/Timer'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { formatAmount, getLPSymbol, ZERO_ADDRESS } from '../../utils/formatNumber'
import VeTHESelect from '../../components/common/VeTHESelect'
import Toggle from '../../components/common/Toggle'
import { useEpochTimer, useVoteEmissions } from '../../hooks/useGeneral'
import { PoolTypes } from '../../config/constants'
import { veTHEsContext } from '../../context/veTHEsConetext'
import usePrices from '../../hooks/usePrices'
import { FusionsContext } from '../../context/FusionsContext'

const sortOptions = [
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Total Votes',
    value: 'votes',
    isDesc: true,
  },
  {
    label: 'Rewards',
    value: 'rewards',
    isDesc: true,
  },
  {
    label: 'Your Vote',
    value: 'your',
    isDesc: true,
  },
]

const Vote = () => {
  const [filter, setFilter] = useState(PoolTypes.ALL)
  const [sort, setSort] = useState({})
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isVoted, setIsVoted] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [veTHE, setVeTHE] = useState(null)
  const [percent, setPercent] = useState({})
  const fusions = useContext(FusionsContext)
  const veTHEs = useContext(veTHEsContext)
  const { voteEmssions } = useVoteEmissions()
  const { days, hours, mins, epoch } = useEpochTimer()
  const navigate = useNavigate()
  const prices = usePrices()
  let { veId } = useParams()

  const avgApr = useMemo(() => {
    if (fusions && fusions.length > 0 && prices) {
      const totalBribe = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.bribeUsd)
      }, new BigNumber(0))
      const totalWeight = fusions.reduce((sum, cur) => {
        return sum.plus(cur.gauge.weight)
      }, new BigNumber(0))
      const totalVoteUsd = totalWeight.times(prices['THE'])
      return totalVoteUsd.isZero() ? 0 : totalBribe.times(52).div(totalVoteUsd).times(100)
    }
    return new BigNumber(0)
  }, [fusions, prices])

  useEffect(() => {
    if (veTHEs && veTHEs.length > 0 && veId) {
      const item = veTHEs.find((ele) => ele.id === veId)
      if (!item) {
        navigate('/404')
        return
      }
      setVeTHE(item)
    }
  }, [veTHEs, veId])

  const totalInfo = useMemo(() => {
    return [
      {
        title: 'Your veLYNX Balance',
        balance: veTHE ? formatAmount(veTHE.voting_amount) : '-',
      },
      {
        title: 'Emissions / % of Vote',
        balance: '$' + formatAmount(voteEmssions),
      },
      {
        title: 'Average Voting APR',
        balance: formatAmount(avgApr) + '%',
      },
      {
        title: `Epoch ${epoch} Ends In`,
        balance: `${days}d ${hours}h ${mins}m`,
      },
    ]
  }, [veTHE, voteEmssions, avgApr, mins])

  const pools = useMemo(() => {
    return fusions
      .filter((pair) => pair.gauge.address !== ZERO_ADDRESS && pair.isValid)
      .map((pair) => {
        let votes = {
          weight: new BigNumber(0),
          weightPercent: new BigNumber(0),
        }
        if (veTHE && veTHE.votes.length > 0) {
          const found = veTHE.votes.find((ele) => ele.address.toLowerCase() === pair.address.toLowerCase())
          if (found) {
            votes = found
          }
        }
        return {
          ...pair,
          votes,
        }
      })
  }, [fusions, veTHE])

  useEffect(() => {
    if (veTHE) {
      setVeTHE(veTHEs.find((item) => item.id === veTHE.id))
    }
  }, [veTHEs, veTHE])

  const votedGauges = useMemo(() => {
    const temp = []
    for (let i = 0; i < Object.keys(percent).length; i++) {
      const key = Object.keys(percent)[i]
      if (!isNaN(Number(percent[key])) && Number(percent[key]) !== 0) {
        const found = pools.find((pool) => pool.address === key)
        temp.push({
          ...found,
          votes: percent[key],
        })
      }
    }
    return temp
  }, [pools, percent])

  const totalPercent = useMemo(() => {
    return Object.values(percent).reduce((sum, current) => {
      return sum + (!current || current === '' ? 0 : Number(current))
    }, 0)
  }, [percent])

  return (
    <>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-20  md:pt-[120px] mx-auto'>
        <div className='lg:flex items-start justify-between lg:space-x-2 xl:space-x-[20px]'>
          <div className='w-full lg:w-[30%] xl:w-1/3'>
            <div className='max-w-[450px]'>
              <h1 className='text-[34px] md:text-4xl xl:text-[42px] font-semibold text-white  f-f-fg'>Vote</h1>
              <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
                Select your veLYNX and use 100% of your votes for one or more pools to earn bribes and trading fees. {''}
                <a href='https://lynex.gitbook.io/lynex/' target='_blank' rel='noreferrer'>
                  <span className='!text-lg text-themeOrange'>Learn More</span>
                </a>
              </p>
            </div>
          </div>
          <Timer arr={totalInfo} className={`w-full lg:w-[70%] xl:w-2/3 mt-4 lg:mt-0`} />
        </div>
        <div className='flex items-center justify-between w-full mt-4 lg:mt-[23px] lg:space-x-7 xl:space-x-[60px] relative'>
          <div className='w-full lg:w-[55%] xl:w-1/2 lg:flex-row flex flex-col-reverse lg:items-center lg:space-x-5'>
            <div className='flex items-center w-full mt-3.5 lg:mt-0'>
              <SearchInput className={''} searchText={searchText} setSearchText={setSearchText} placeholder='Search LP' />
              {/* filter button for mobile */}
              <button
                onClick={() => {
                  setMobileFilter(!mobileFilter)
                }}
                className='w-12 flex-shrink-0 h-[42px] lg:hidden ml-3'
              >
                <img alt='' className='w-12 h-[42px]' src='/images/liquidity/filter.svg' />
              </button>
            </div>
            <VeTHESelect className={'lg:max-w-[300px] w-full'} isSelected={veTHE} setIsSelected={setVeTHE} />
          </div>
        </div>
        <div className='flex items-center justify-between w-full mt-4 lg:mt-[23px] lg:space-x-7 xl:space-x-[60px] relative'>
          {/* for desktop */}
          <div className='w-full hidden lg:flex items-center space-x-8'>
            <TabFilter data={Object.values(PoolTypes)} filter={filter} setFilter={setFilter} />
            <div className='flex items-center space-x-2'>
              <Toggle checked={isVoted} onChange={() => setIsVoted(!isVoted)} toggleId='isVoted' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Voted Only</p>
            </div>
          </div>
          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              setFilter={setFilter}
              filter={filter}
              tabs={Object.values(PoolTypes)}
              isVote={true}
              isVoted={isVoted}
              setIsVoted={setIsVoted}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
            />
          )}
        </div>
        <div className='my-4'>
          {votedGauges.length > 0 && (
            <>
              <p className='text-white f-f-fg'>Your Votes:</p>
              <div className='flex overflow-auto mb-4 lg:mb-0 pb-2 lg:pb-0  w-full mt-1.5 lg:-mt-2.5 space-x-4 lg:space-x-8'>
                {votedGauges.map((pool, idx) => {
                  return (
                    <div key={idx} className='flex lg:my-4 flex-shrink-0 max-w-[280px] lg:max-w-[264px] xl:max-w-[275px] rounded-md border border-themeOrange'>
                      <div className={`flex items-center w-3/4 rounded-tl-md rounded-bl-md border-r border-themeOrange`}>
                        <button
                          className='border-r border-themeOrange md:w-10 md:h-10'
                          onClick={() => {
                            setPercent({
                              ...percent,
                              [pool.address]: '',
                            })
                          }}
                        >
                          <img alt='' src='/images/vote/remove-button.svg' />
                        </button>
                        <div className='flex items-center w-full space-x-2 ml-1.5 py-1.5'>
                          <div className='flex items-center  -space-x-2'>
                            <img className='relative w-6 h-6 z-10' alt='' src={pool.token0.logoURI} />
                            <img className='relative w-6 h-6 z-[5]' alt='' src={pool.token1.logoURI} />
                          </div>
                          <div className=' text-white'>
                            <p className='font-semibold f-f-fg lg:text-base text-[14px] leading-5'>{getLPSymbol(pool)}</p>
                            <p className='tracking-[0.66px] text-[9px] leading-none'>{pool.title}</p>
                          </div>
                        </div>
                      </div>
                      <div className='relative w-1/5 lg:w-[28%]'>
                        <input
                          onChange={(e) => {
                            const val = isNaN(Number(percent[pool.address])) ? 0 : Number(percent[pool.address])
                            const newVal = isNaN(Number(e.target.value)) || Number(e.target.value) < 0 ? 0 : Math.floor(Number(e.target.value))
                            const maxValue = 100 - totalPercent + val === 0 ? '' : 100 - totalPercent + val
                            let final = newVal === 0 ? '' : totalPercent - val + newVal > 100 ? maxValue : newVal
                            setPercent({
                              ...percent,
                              [pool.address]: !e.target.value ? '' : final,
                            })
                          }}
                          type={'number'}
                          className='py-3 w-[90%] pl-3 text-white font-medium text-lg lg:text-xl bg-transparent'
                          value={pool.votes}
                        />
                        <span className='text-white font-medium text-lg lg:text-xl absolute right-0 z-10 mt-3 -mr-1.5 lg:mr-1.5'>%</span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
        <Table
          pools={pools}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          filter={filter}
          searchText={searchText}
          isVoted={isVoted}
          veTHE={veTHE}
          percent={percent}
          setPercent={setPercent}
          totalPercent={totalPercent}
        />
      </div>
    </>
  )
}

export default Vote
