import { useContext, useEffect, useState } from 'react'
import groupBy from 'lodash/groupBy'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import { GammaContext } from '../../context/GammaContext'
import { farms } from '../../config/constants/farms'
import { useWeb3React } from '@web3-react/core'
import useRefresh from '../useRefresh'
import { getAddress, getPreFarmingAddress, getPreMiningAddress } from '../../utils/addressHelpers'
import { multicall } from '../../utils/multicall'
import BigNumber from 'bignumber.js'
import { ERC20Abi, PreFarmingAbi } from '../../config/abi'
import { fetchFarmUserAllowances, fetchFarmUserTokenBalances, fetchFarmUserStakedBalances, fetchFarmUserEarnings } from './fetchFarmUser'
import { PresaleLynxPrice, defaultChainId } from '../../config/constants'
import { fromWei } from '../../utils/formatNumber'

export const BIG_TEN = new BigNumber(10)
export const BIG_ZERO = new BigNumber(0)

export const usePollFarmsData = (includeArchive = false) => {
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()
  const [fetchedFarms, setFetchedFarms] = useState([])
  const [fetchedFarmsP2, setFetchedFarmsP2] = useState([])
  const [userData, setUserData] = useState([])
  const [userDataP2, setUserDataP2] = useState([])
  const assets = useContext(BaseAssetsConetext)
  const gammas = useContext(GammaContext)

  useEffect(() => {
    const farmsToFetch = includeArchive ? farms[defaultChainId] : farms[defaultChainId]
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    const fetchFarmUserDataAsync = async ({ account, pids }) => {
      return fetchFarmUserData(account, pids)
    }

    const fetchFarmsAsync = async () => {
      // TODO: look into filtering

      const processedFarms = await fetchFarms(farmsToFetch)
      let userDataResult
      if (account) {
        userDataResult = await fetchFarmUserDataAsync({ account, pids })
        const groupedUserData = groupBy(userDataResult, 'address')
        setUserData(groupedUserData[getPreFarmingAddress()])
        setUserDataP2(groupedUserData[getPreMiningAddress()])
      }

      const computedFarms = processedFarms.map((farm) => {
        let stakeToken = assets.find((item) => item.address.toLowerCase() === getAddress(farm.stakeTokenAddress).toLowerCase())
        if (stakeToken) stakeToken.logoURIs = [stakeToken?.logoURI]
        if (farm.isLp) {
          farm.token1 = assets.find((item) => item.address.toLowerCase() === getAddress(farm.token1.address).toLowerCase())
          farm.token0 = assets.find((item) => item.address.toLowerCase() === getAddress(farm.token0.address).toLowerCase())
          farm.gammaPool = gammas.find((item) => item.address.toLowerCase() === getAddress(farm.stakeTokenAddress).toLowerCase())
          stakeToken = {
            symbol: farm.token0 && farm.token1 ? `${farm.token0.symbol}/${farm.token1.symbol}` : '~',
            logoURIs: farm.token0 && farm.token1 ? [farm.token0.logoURI, farm.token1.logoURI] : [],
            address: getAddress(farm.stakeTokenAddress),
          }
        }

        const rewardToken = assets.find((item) => item.address.toLowerCase() === getAddress(farm.token.address).toLowerCase())
        const userInfo = userDataResult ? userDataResult.find((data) => data.pid === farm.pid && data.address === farm.address) : {}

        farm.totalStakedUsd = farm.gammaPool ? fromWei(farm.gammaPool.lpPrice.times(farm.totalStaked), farm.stakeToken?.decimals) : new BigNumber(0)
        farm.userStakedUsd =
          farm.gammaPool && userInfo?.stakedBalance
            ? fromWei(farm.gammaPool.lpPrice.times(userInfo.stakedBalance), farm.stakeToken?.decimals)
            : new BigNumber(0)
        const dailyUsdRewards = fromWei(farm.rewardsPerSecond).times(PresaleLynxPrice).times(86400)
        const yearlyUsdRewards = dailyUsdRewards.times(365)
        farm.apr = farm.totalStakedUsd.gt(0) ? yearlyUsdRewards.div(farm.totalStakedUsd).times(100).toNumber() : 0
        farm.dailyApr = farm.totalStakedUsd.gt(0) ? dailyUsdRewards.div(farm.totalStakedUsd).times(100).toNumber() : 0
        farm.apy = ((1 + farm.apr / 365 / 100) ** 365 - 1) * 100
        return { ...farm, stakeToken, rewardToken, userInfo }
      })
      const groupedFarms = groupBy(computedFarms, 'address')
      setFetchedFarms(groupedFarms[getPreFarmingAddress()])
      setFetchedFarmsP2(groupedFarms[getPreMiningAddress()])
    }

    if (getPreFarmingAddress()) fetchFarmsAsync()
  }, [includeArchive, slowRefresh, account, setUserData, assets])
  return { fetchedFarms, fetchedFarmsP2, userData, userDataP2 }
}

const fetchFarms = async (farmsToFetch) => {
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      const farm = await fetchFarm(farmConfig)
      return farm
    }),
  )
  return data
}

const fetchFarm = async (farm) => {
  const farmPublicData = await fetchPublicFarmData(farm)

  return { ...farm, ...farmPublicData }
}

async function fetchFarmUserData(account, pids) {
  const chainFarms = farms[defaultChainId]
  const farmsToFetch = chainFarms.filter((farmConfig) => pids.includes(farmConfig.pid))
  const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)
  const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
  const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)

  return userFarmAllowances.map((farmAllowance, index) => {
    return {
      pid: farmsToFetch[index].pid,
      address: farmsToFetch[index].address,
      allowance: userFarmAllowances[index],
      tokenBalance: new BigNumber(userFarmTokenBalances[index]),
      stakedBalance: new BigNumber(userStakedBalances[index]),
      earnings: userFarmEarnings[index],
    }
  })
}

const fetchPublicFarmData = async (farm) => {
  try {
    const { pid, stakeTokenAddress, token, quoteToken, address } = farm
    const lpAddress = getAddress(stakeTokenAddress)
    const calls = [
      // Balance of token in the LP contract
      {
        address: getAddress(token.address),
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of quote token on LP contract
      {
        address: getAddress(quoteToken.address),
        name: 'balanceOf',
        params: [lpAddress],
      },
      // Balance of LP tokens in the master chef contract
      {
        address: lpAddress,
        name: 'balanceOf',
        params: [address],
      },
      // Total supply of LP tokens
      {
        address: lpAddress,
        name: 'totalSupply',
      },
      // Token decimals
      {
        address: getAddress(token.address),
        name: 'decimals',
      },
      // Quote token decimals
      {
        address: getAddress(quoteToken.address),
        name: 'decimals',
      },
    ]

    const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] = await multicall(ERC20Abi, calls)

    // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
    const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

    // Raw amount of token in the LP, including those not staked
    const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(BIG_TEN.pow(tokenDecimals))
    const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(BIG_TEN.pow(quoteTokenDecimals))

    // Amount of token in the LP that are staked in the MC (i.e amount of token * lp ratio)
    const tokenAmountMc = tokenAmountTotal.times(lpTokenRatio)
    const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

    // Total staked in LP, in quote token value
    const lpTotalInQuoteToken = quoteTokenAmountMc.times(new BigNumber(2))

    // Only make masterchef calls if farm has pid
    const [info, totalAllocPoint, rewardPerSecond, harvestEnable, startTime, endTime] =
      pid || pid === 0
        ? await multicall(PreFarmingAbi, [
            {
              address: address,
              name: 'poolInfo',
              params: [pid],
            },
            {
              address: address,
              name: 'totalAllocPoint',
            },
            {
              address: address,
              name: 'rewardPerSecond',
            },
            {
              address: address,
              name: 'harvestEnable',
            },
            {
              address: address,
              name: 'startTime',
            },
            {
              address: address,
              name: 'endTime',
            },
          ])
        : [null, null]

    const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
    const depositFee = info ? new BigNumber(info.depositFeeBP) : BIG_ZERO
    const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO
    const rewardsPerSecond = totalAllocPoint ? new BigNumber(rewardPerSecond).times(poolWeight) : BIG_ZERO

    return {
      poolInfo: info,
      totalStaked: info.totalStaked.toString(),
      tokenAmountMc: tokenAmountMc.toJSON(),
      quoteTokenAmountMc: quoteTokenAmountMc.toJSON(),
      tokenAmountTotal: tokenAmountTotal.toJSON(),
      quoteTokenAmountTotal: quoteTokenAmountTotal.toJSON(),
      lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
      apr: new BigNumber(0),
      apy: new BigNumber(0),
      totalStakedUsd: new BigNumber(0),
      userStakedUsd: new BigNumber(0),
      lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
      tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
      poolWeight: poolWeight.toJSON(),
      multiplier: `${allocPoint.div(100).toString()}X`,
      fee: `${depositFee.div(100).toString()}%`,
      rewardsPerSecond: rewardsPerSecond.toJSON(),
      harvestEnable: harvestEnable[0],
      startTime: startTime[0].toNumber(),
      endTime: endTime[0].toNumber(),
    }
  } catch (e) {
    console.error(e)
    console.warn('Something went wrong fetchinf farm', farm)
    return {
      tokenAmountMc: '0',
      quoteTokenAmountMc: '0',
      tokenAmountTotal: '0',
      quoteTokenAmountTotal: '0',
      lpTotalSupply: '0',
      lpTotalInQuoteToken: '0',
      tokenPriceVsQuote: '0',
      poolWeight: '1',
      multiplier: `~`,
      fee: `~%`,
    }
  }
}
