import React, { useContext, useMemo, useState } from 'react'
import TablePairs from '../../components/pages/liquidity/tablePairs'
import StyledButton from '../../components/common/Buttons/styledButton'
import Toggle from '../../components/common/Toggle'
import TransparentButton from '../../components/common/Buttons/transparentButton'
import { useNavigate } from 'react-router-dom'
import TabFilter from '../../components/common/TabFilter'
import MobileFilterModal from '../../components/common/MobileFilterModal'
import { useAllHarvest } from '../../hooks/useGauge'
import { NewPools, PoolTypes } from '../../config/constants'
import NewTablePairs from '../../components/pages/liquidity/newTablePairs'
import { ZERO_ADDRESS } from '../../utils/formatNumber'
import { FusionsContext } from '../../context/FusionsContext'

const sortOptions = [
  {
    label: 'APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Total Staked',
    value: 'tvl',
    isDesc: true,
  },
  {
    label: 'My Pool',
    value: 'pool',
    isDesc: true,
  },
  {
    label: 'My Stake',
    value: 'stake',
    isDesc: true,
  },
  {
    label: 'Earnings',
    value: 'earn',
    isDesc: true,
  },
]
const newSortOptions = [
  {
    label: 'Projected APR',
    value: 'apr',
    isDesc: true,
  },
  {
    label: 'Total Staked',
    value: 'tvl',
    isDesc: true,
  },
  {
    label: 'My Pool',
    value: 'pool',
    isDesc: true,
  },
  {
    label: 'My Stake',
    value: 'stake',
    isDesc: true,
  },
  {
    label: 'Earnings',
    value: 'earn',
    isDesc: true,
  },
]

const timestamp = 1683158400

const Index = () => {
  const [filter, setFilter] = useState(PoolTypes.ALL)
  const [mobileFilter, setMobileFilter] = useState(false)
  const [isStaked, setIsStaked] = useState(false)
  const [isInactive, setIsInactive] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [sort, setSort] = useState({})
  const [newSort, setNewSort] = useState({})
  const fusions = useContext(FusionsContext)
  const { onAllHarvest } = useAllHarvest()

  const filteredPairs = useMemo(() => {
    let final
    if (isInactive) {
      final = fusions.filter((ele) => ele.gauge.apr.isZero())
    } else {
      final = fusions.filter((ele) => !ele.gauge.apr.isZero())
    }
    const result = final.filter((item) => {
      const isCorrect = item.gauge.address !== ZERO_ADDRESS && item.isValid
      return isCorrect && ((isStaked && !item.account.gaugeBalance.isZero()) || !isStaked)
    })
    const res = filter === PoolTypes.ALL ? result : result.filter((item) => item.kind === filter)
    if (!searchText || searchText === '') {
      return res
    }
    return (
      res &&
      res.filter((item) => {
        const withSpace = item.symbol.replace('/', ' ')
        const withComma = item.symbol.replace('/', ',')
        return (
          item.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
          withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
          withComma.toLowerCase().includes(searchText.toLowerCase())
        )
      })
    )
  }, [fusions, filter, searchText, isStaked, isInactive])

  const newFilteredPairs = useMemo(() => {
    if (new Date().getTime() / 1000 < timestamp) {
      return fusions.filter((item) => NewPools.includes(item.address))
    }
    return []
  }, [fusions])

  const oldFilteredPairs = useMemo(() => {
    // if (new Date().getTime() / 1000 < timestamp) {
    //   return filteredPairs.filter((item) => !NewPools.includes(item.address))
    // }
    return filteredPairs
  }, [filteredPairs])

  const sortedPairs = useMemo(() => {
    return oldFilteredPairs.sort((a, b) => {
      let res
      switch (sort.value) {
        case 'apr':
          res = a.gauge.apr
            .minus(b.gauge.apr)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'tvl':
          res = a.gauge.tvl
            .minus(b.gauge.tvl)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'pool':
          res = a.account.totalUsd
            .minus(b.account.totalUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'stake':
          res = a.account.stakedUsd
            .minus(b.account.stakedUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'earn':
          res = a.account.earnedUsd
            .minus(b.account.earnedUsd)
            .times(sort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [oldFilteredPairs, sort])

  const newSortedPairs = useMemo(() => {
    return newFilteredPairs.sort((a, b) => {
      let res
      switch (newSort.value) {
        case 'apr':
          res = a.gauge.projectedApr
            .minus(b.gauge.projectedApr)
            .times(newSort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'tvl':
          res = a.gauge.tvl
            .minus(b.gauge.tvl)
            .times(newSort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'pool':
          res = a.account.totalUsd
            .minus(b.account.totalUsd)
            .times(newSort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'stake':
          res = a.account.stakedUsd
            .minus(b.account.stakedUsd)
            .times(newSort.isDesc ? -1 : 1)
            .toNumber()
          break
        case 'earn':
          res = a.account.earnedUsd
            .minus(b.account.earnedUsd)
            .times(newSort.isDesc ? -1 : 1)
            .toNumber()
          break

        default:
          break
      }
      return res
    })
  }, [newFilteredPairs, newSort])

  const earnedPairs = useMemo(() => {
    return fusions.filter((pair) => !pair.account.earnedUsd.isZero())
  }, [fusions, isInactive])

  const navigate = useNavigate()

  const handlePopup = () => {
    navigate('/liquidity/fusion')
  }

  return (
    <>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-20  md:pt-[120px] mx-auto'>
        <div className='lg:flex items-end justify-between'>
          <div className='max-w-[425px] w-full'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Liquidity</h1>
            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Pair your tokens to provide liquidity. Stake the LP tokens to earn LYNX.{' '}
              <a href='https://lynex.gitbook.io/lynex/' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row justify-between mt-4'>
          <div className='mt-3 lg:mt-0 w-full flex-col flex md:flex-row items-center space-y-3 md:space-y-0 md:space-x-3 '>
            <StyledButton
              disabled={earnedPairs.length === 0}
              onClickHandler={() => {
                onAllHarvest(earnedPairs)
              }}
              content={`CLAIM ALL EARNINGS (${earnedPairs.length})`}
              className='py-[13px] md:py-[14.53px] text-white w-full md:w-1/2 mdLg:w-auto flex items-center justify-center text-sm tracking-[0.84px] xl:text-[17px] md:tracking-[1.36px] px-[33px] lg:px-[43px] xl:px-[33px] rounded-[3px]'
            />
            <TransparentButton
              content={'ADD/REMOVE LIQUIDITY'}
              className={
                'h-[42px] md:h-[52px] px-7 text-white lg:hidden flex flex-col items-center justify-center w-full md:w-1/2 mdLg:w-auto text-sm mdLg:text-[17px] tracking-[1.12px] md:tracking-[1.36px]'
              }
              onClickHandler={handlePopup}
            />
          </div>
          <div className='w-full mt-3 lg:mt-0 flex lg:items-center space-x-3 lg:space-x-5'>
            <div className='border border-themeOrange w-full rounded-[3px] flex items-center lg:max-w-[250px] relative'>
              <img className='pointer-events-none absolute left-3 w-6 h-6' alt='' src='/images/svgs/search.svg' />
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder='Search Pair or Token'
                className='bg-body placeholder-[#757384] h-[42px] xl:h-[52px] w-full text-themeBlack text-sm xl:text-base pl-10 xl:pl-[41px] pr-4 py-3 md:py-[18px] rounded-[3px]'
              />
            </div>
            <TransparentButton
              content={'ADD/REMOVE LIQUIDITY'}
              className={
                'xl:max-w-[290px] h-[52px] px-3 xl:px-7 text-white hidden lg:flex flex-col items-center justify-center w-full text-sm xl:text-[14px] tracking-[1.04px] xl:tracking-[1.36px]'
              }
              onClickHandler={handlePopup}
            />
            {/* filter button for mobile */}
            <button
              onClick={() => {
                setMobileFilter(!mobileFilter)
              }}
              className='w-12 flex-shrink-0 h-[42px] lg:hidden'
            >
              <img alt='' className='w-12 h-[42px]' src='/images/liquidity/filter.svg' />
            </button>
          </div>
        </div>
        <div className='flex items-center justify-between w-full mt-[23px] lg:space-x-8 xl:space-x-[60px] relative'>
          {/* for desktop */}
          <div className='w-full hidden lg:flex items-center space-x-8'>
            <TabFilter data={Object.values(PoolTypes)} filter={filter} setFilter={setFilter} />
            <div className='flex items-center space-x-2'>
              <Toggle checked={isStaked} onChange={() => setIsStaked(!isStaked)} toggleId='isStaked' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Staked Only</p>
            </div>
            <div className='flex items-center space-x-2'>
              <Toggle checked={isInactive} onChange={() => setIsInactive(!isInactive)} toggleId='isInactive' />
              <p className='text-[#DEDBF2] text-sm xl:text-[17px] whitespace-nowrap'>Inactive Gauges</p>
            </div>
          </div>

          {/* mobile filters popup */}
          {mobileFilter && (
            <MobileFilterModal
              setMobileFilter={setMobileFilter}
              setFilter={setFilter}
              filter={filter}
              tabs={Object.values(PoolTypes)}
              isStaked={isStaked}
              setIsStaked={setIsStaked}
              isInactive={isInactive}
              setIsInactive={setIsInactive}
              sort={sort}
              setSort={setSort}
              sortOptions={sortOptions}
            />
          )}
        </div>
        {/* <TablePairs pairsData={sortedPairs} sort={sort} setSort={setSort} sortOptions={sortOptions} active={active} filter={filter} /> */}
        <TablePairs
          pairsData={sortedPairs}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          filter={filter}
          searchText={searchText}
          isStaked={isStaked}
        />
        <NewTablePairs pairsData={newSortedPairs} sort={newSort} setSort={setNewSort} sortOptions={newSortOptions} filter={filter} searchText={searchText} />
      </div>
    </>
  )
}

export default Index
