import React, { useState } from 'react'
import TransparentButton from '../../common/Buttons/transparentButton'
import ReactTooltip from 'react-tooltip'
import { useWeb3React } from '@web3-react/core'
import Sticky from 'react-stickynode'
import useWalletModal from '../../../hooks/useWalletModal'
import { formatAmount, getLP0Symbol, getLP1Symbol } from '../../../utils/formatNumber'
import { useNavigate } from 'react-router-dom'
// import { useHarvest } from '../../../hooks/useGauge'
import DepositModal from './depositModal'

const Item = ({ usd, content, account, idx, type }) => {
  const [arrowReverse, setArrowReverse] = useState()
  return account || type === 'tvl' ? (
    <div className='flex flex-col items-start justify-center'>
      <div
        data-tip
        data-for={`new-${type}-${idx}`}
        onMouseEnter={() => {
          setArrowReverse(`new-${type}-${idx}`)
        }}
        onMouseLeave={() => {
          setArrowReverse(null)
        }}
        className='text-base sm:text-[14px] lg:text-[15px] xl:text-[14px] flex items-center cursor-pointer space-x-1.5'
      >
        <p>{'$' + formatAmount(usd, true)}</p>
        <button className={`${arrowReverse === `new-${type}-${idx}` ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 ease-in-out`}>
          <img alt='' src='/images/liquidity/small-arrow-bottom.svg' />
        </button>
      </div>
      <ReactTooltip
        className='max-w-[180px] !bg-themeBlack !border !border-white !text-[#E6E6E6] !text-base !py-[9px] !px-6 !opacity-100 after:!bg-body'
        id={`new-${type}-${idx}`}
        place='right'
        effect='solid'
      >
        {content}
      </ReactTooltip>
    </div>
  ) : (
    <div className='text-base sm:text-[14px] lg:text-[15px] xl:text-[14px]'>-</div>
  )
}

const TableRow = ({ item, isLast, idx }) => {
  // TODO: Open when position
  const [isOpen, setIsOpen] = useState(false)
  const { account } = useWeb3React()
  const { openWalletModal } = useWalletModal()
  const navigate = useNavigate()
  // const { onHarvest, pending } = useHarvest()
  const [manage, setManage] = useState(false)

  return (
    <div
      key={idx}
      className={`
    ${isLast ? 'rounded-b-[5px]' : ''}
    ${idx === 0 && 'rounded-t-lg'}
    mb-px flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between  text-[#DEDBF2] p-4 lg:py-5 px-4 xl:px-6 bg-themeBlack`}
    >
      <div className='w-full  lg:w-[20%]'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-3 mr:0 lg:mr-2'>
            <div className='flex items-center -space-x-2'>
              <img className='relative z-10' alt='' src={item.token0.logoURI} width={30} height={30} />
              <img className='relative z-[5]' alt='' src={item.token1.logoURI} width={30} height={30} />
            </div>
            <div>
              <div className='flex flex-wrap text-base xl:text-[19px] leading-[30px] font-medium'>
                <span>{getLP0Symbol(item)}</span>/<span>{getLP1Symbol(item)}</span>
              </div>
              <p className='tracking-[0.78px] text-[13px] leading-none'>{item.title}</p>
            </div>
          </div>
          <button
            className='lg:hidden'
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            <img alt='' className={`${isOpen ? 'rotate-180' : ''} transform`} src='/images/swap/dropdown-arrow.png' />
          </button>
        </div>
      </div>
      {/* <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[14%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Projected APR</p>
        <div className='flex flex-col items-start justify-center text-base sm:text-[14px] lg:text-[15px] xl:text-[14px]'>{formatAmount(item.apr, true)}%</div>
      </div> */}
      <div className='flex flex-col w-1/2 mt-2 lg:mt-0 items-start lg:w-[11%] justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Total Staked</p>
        <Item
          account={account}
          type={'tvl'}
          usd={item.tvl}
          content={
            <>
              {formatAmount(item.token0.tvl)} {item.token0.symbol}
              <br />
              {formatAmount(item.token1.tvl)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div>
      <div className='flex flex-col mt-2 lg:mt-0 w-1/2 lg:w-[11%] items-start justify-center'>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Pool APR</p>
        <div className='flex flex-col items-start justify-center text-base sm:text-[14px] lg:text-[15px] xl:text-[14px]'>
          {formatAmount(item.poolApr, true)}%
        </div>
      </div>
      {/* second row */}
      {/* <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start mt-3 lg:mt-0 w-1/2 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Pool</p>
        <Item
          account={account}
          type={'pool'}
          usd={item.account?.totalUsd}
          content={
            <>
              TODO
              {formatAmount(item.account.total0)} {item.token0.symbol}
              <br />
              {formatAmount(item.account.total1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div>
      <div className={`${isOpen ? 'flex' : 'hidden'} lg:flex flex-col items-start w-1/2 mt-3 lg:mt-0 lg:w-[11%] justify-center`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>My Stake</p>
        <Item
          account={account}
          type={'stake'}
          usd={item.account?.stakedUsd}
          content={
            <>
            TODO
              {formatAmount(item.account.staked0)} {item.token0.symbol}
              <br />
              {formatAmount(item.account.staked1)} {item.token1.symbol}
            </>
          }
          idx={idx}
        />
      </div> */}
      {/* <div className={`${isOpen ? 'block' : 'hidden'} lg:block  w-1/2 lg:w-[8%] mt-2 lg:mt-0`}>
        <p className='lg:hidden text-sm f-f-fg font-semibold'>Earnings</p>
        <Item account={account} type={'earning'} usd={item.account.earnedUsd} content={<>{formatAmount(item.account.gaugeEarned)} THE</>} idx={idx} />
      </div> */}
      <div className={`${isOpen ? 'block' : 'hidden'} lg:block w-full lg:w-[25%] mt-3.5 lg:mt-0`}>
        {account ? (
          <div className='space-x-5 xl:space-x-6 w-full flex items-center lg:justify-end'>
            <TransparentButton
              content={'Manage'}
              onClickHandler={() => {
                navigate(`/liquidity/fusion?currency0=${item.token0.address}&currency1=${item.token1.address}`)
              }}
              fontWeight={'font-medium'}
              className='h-10 px-[26px] text-white flex  max-w-[96px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[14px] w-full'
            />
            {/* <button
              disabled={item.account?.gaugeEarned.isZero() || pending}
              className={`${item.account?.gaugeEarned.isZero() || pending ? 'opacity-[0.33] cursor-not-allowed ' : ''}text-base text-gray`}
              onClick={() => {
                onHarvest(item)
              }}
            >
              Claim Earnings
            </button> */}
          </div>
        ) : (
          <div className='w-full  flex  items-center lg:justify-end'>
            <TransparentButton
              onClickHandler={openWalletModal}
              content={'Connect Wallet'}
              className='
  h-10 px-[26px] text-white flex lg:max-w-[173px] whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[14px] w-full '
            />
          </div>
        )}
      </div>
      {manage && <DepositModal isOpen={manage} setIsOpen={setManage} pair={item} />}
    </div>
  )
}

const LpTablePairs = ({ pairsData, sort, setSort, sortOptions }) => {
  return (
    <>
      {/* for desktop */}
      <div className='w-full mt-11'>
        <div className='w-full'>
          {pairsData.length > 0 && (
            <>
              <Sticky
                enabled={true}
                innerActiveClass={'bg-themeOrange'}
                top={95}
                bottomBoundary={1200}
                activeClass={''}
                innerClass={'px-6 lg:flex justify-between hidden z-[100] py-[0.475rem] lg:!-mb-[19px] xl:!mb-0 lg:!top-[-19px] xl:!top-[0]'}
                className={`z-[100]`}
              >
                <div className='w-[20%] font-medium text-[14px] xl:text-[16px] text-white f-f-fg'></div>
                {sortOptions.map((option, index) => (
                  <div
                    className={`${index === 4 ? 'w-[8%]' : index === 0 ? 'w-[14%]' : 'w-[11%]'} font-medium text-[14px] xl:text-[16px] text-white f-f-fg`}
                    key={`header-${index}`}
                  >
                    <div
                      onClick={() => {
                        setSort({
                          ...option,
                          isDesc: sort.value === option.value ? !sort.isDesc : true,
                        })
                      }}
                      className='flex items-center cursor-pointer space-x-1 -ml-1 relative'
                    >
                      {sort.value === option.value && (
                        <button className={`${sort.isDesc ? '' : 'rotate-180'} transform absolute -left-3.5`}>
                          <img alt='' src='/images/liquidity/arrow-bottom.svg' />
                        </button>
                      )}
                      <p className='flex items-center'>{option.label}</p>
                    </div>
                  </div>
                ))}
                <div className='w-[25%] font-medium text-[14px] xl:text-[16px] text-white f-f-fg'></div>
              </Sticky>
              <div className='flex flex-col rounded-[5px] gradient-bg p-px'>
                {pairsData.map((item, idx) => {
                  return <TableRow isLast={idx === pairsData.length - 1} idx={idx} item={item} key={`row-${idx}`} />
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default LpTablePairs
