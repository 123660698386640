import React from 'react'
import { useNavigate } from 'react-router-dom'
// import Ghost from '../../../common/buttons/ghost'
import CTA from '../../../common/Buttons/cta'
import './style.scss'

const Start = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className='mx-auto container-4 relative z-20 mb-6'>
        <div className='start-img-item bg-themeBlack border border-themeOrange'>
          <div className='start-img-item-body f-f-fg'>
            <div className='left-part'>
              <div className='text-themeOrange left-title f-f-fg'>Start Now</div>
              <div className='left-para'>
                <p>Start build your passive income streams right away.</p>
                <p>No registration required.</p>
              </div>
            </div>
            <div className='right-part'>
              <div className='first'>
                <CTA
                  icon={true}
                  title={'SWAP NOW'}
                  onClickHandler={() => {
                    navigate('/swap')
                  }}
                />
              </div>
              {/* <Ghost title={'CONNECT WALLET'} /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Start
