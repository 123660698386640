import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import ConnectWallet from '../../connectWallet'
import useAuth from '../../../hooks/useAuth'
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics'
import './style.scss'
import OutsideClickHandler from 'react-outside-click-handler'
import { connectors } from '../../../config/constants'
import Transaction from '../Transaction'
import { useSelector } from 'react-redux'
import useWalletModal from '../../../hooks/useWalletModal'
import usePrices from '../../../hooks/usePrices'
import { formatAmount } from '../../../utils/formatNumber'
import Menu from './Menu'

const v1ShowKey = 'v1Show'

const links = [
  {
    name: 'SWAP',
    link: '/swap',
  },
  {
    name: 'LIQUIDITY',
    link: '/liquidity-info',
  },
  {
    name: 'PRE MINING',
    link: '/mining',
    class: 'special',
  },
  // {
  //   name: 'LOCK',
  //   link: '/lock',
  // },
  // {
  //   name: 'VOTE',
  //   link: '/vote',
  // },
  // {
  //   name: 'REWARDS',
  //   link: '/rewards',
  // },
  // {
  //   name: 'GAUGES',
  //   link: '/whitelist',
  // },
  // {
  //   name: 'theNFT',
  //   link: '/theNFT',
  // },
  // {
  //   name: 'REFERRAL',
  //   link: '/referral',
  // },
  {
    name: 'MORE',
    dropdown: true,
    link: [
      {
        name: 'Analytics',
        link: '/analytics',
      },
      // {
      //   name: 'Analytics',
      //   link: 'https://info.thena.fi/',
      //   external: true,
      // },
      // {
      //   name: 'V1',
      //   link: 'https://v1.thena.fi/',
      //   external: true,
      // },
      {
        name: 'Docs',
        link: 'https://lynex.gitbook.io/',
        external: true,
      },
    ],
  },
]

const MobileMenu = ({ item, idx, route }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <li key={idx} className={`links`}>
      {item.dropdown ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsOpen(false)
          }}
        >
          <div className='relative'>
            <div
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              className='flex items-center space-x-1 cursor-pointer relative z-10  font-light text-white'
            >
              <span>{item.name}</span>
              <img
                alt='dropdown'
                src='/images/header/dropdown-arrow.svg'
                className={`${!isOpen ? 'rotate-180' : 'rotate-0'} transition-all duration-150 ease-in-out`}
              />
            </div>
            {isOpen && (
              <div className='py-3 px-[22px] w-[205px] absolute top-10 border border-themeOrange bg-themeBlack -left-[74px] z-40 rounded-[3px] flex flex-col text-white text-[17px] leading-9'>
                {item.link.map((_item, j) => {
                  return _item.external ? (
                    <div
                      className='doc-link'
                      id={'test'}
                      key={`subitem-${j}`}
                      onClick={() => {
                        window.open(_item.link, '_blank')
                        setIsOpen(false)
                      }}
                    >
                      {_item.name}
                    </div>
                  ) : (
                    <Link
                      key={j}
                      onClick={() => {
                        setIsOpen(false)
                      }}
                      className={`${route.pathname === _item.link && 'text-sky'} no-link text-white`}
                      to={_item.link}
                    >
                      {_item.name}
                    </Link>
                  )
                })}
              </div>
            )}
          </div>
        </OutsideClickHandler>
      ) : (
        <Link className={`${route.pathname === item.link && 'text-sky'} no-link text-white`} to={item.link}>
          {item.name}
        </Link>
      )}
    </li>
  )
}

const Header = () => {
  useGoogleAnalytics()
  const route = useLocation()
  const [open, setOpen] = useState(false)
  const [connector, setConnector] = useState(null)
  const [secondDrop, setSecondDrop] = useState(false)
  const [mobileDrop, setMobileDrop] = useState(false)
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const [scroll, setScroll] = useState(false)
  const [selected, setSelected] = useState(false)
  const [isBanner, setIsBanner] = useState(false)
  const prices = usePrices()

  // useEffect(() => {
  //   const v1key = window.localStorage.getItem(v1ShowKey)
  //   setIsBanner(!v1key)
  // }, [])

  useEffect(() => {
    if (prices && prices['THE']) {
      document.title = `Lynex - $${formatAmount(prices['THE'])}`
    }
  }, [prices])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 30)
    })
  }, [])
  const { isWalletOpen } = useSelector((state) => state.wallet)
  const { openWalletModal, closeWalletModal } = useWalletModal()

  useEffect(() => {
    setOpen(false)
    closeWalletModal()
    setMobileDrop(false)
  }, [route.pathname])

  useEffect(() => {
    if (account) {
      if (!connector && !selected) {
        setConnector(connectors[0])
        setSelected(false)
      }
    } else {
      setConnector(null)
    }
  }, [account, connector, selected, setSelected])

  const dropDownhandler = () => {
    if (connector) {
      setSecondDrop(!secondDrop)
    } else {
      openWalletModal()
    }
  }

  const onDisconnect = () => {
    logout()
    setConnector(null)
    setSecondDrop(false)
  }

  const onMobileDisconnect = () => {
    logout()
    setConnector(null)
    setMobileDrop(false)
  }

  const handleClose = () => {
    window.localStorage.setItem(v1ShowKey, 'closed')
    setIsBanner(false)
  }

  return (
    <>
      {isWalletOpen && <ConnectWallet setConnector={setConnector} setSelected={setSelected} />}
      <div className={`header-wrap fixed w-full z-[120]`}>
        {isBanner && (
          <div className='flex justify-center items-center w-full h-16 lg:h-10 px-4 bg-[#0000AF] relative z-500'>
            <div className='text-white text-[12px] md:text-[16px] mr-8'>
              We have migrated our contracts to V2. To claim past rewards and withdraw LPs, please visit{' '}
              <a href='https://v1.thena.fi' className='underline underline-offset-2'>
                https://v1.thena.fi
              </a>
            </div>
            <img
              onClick={() => handleClose()}
              src='/images/common/close-button1.svg'
              alt=''
              className='absolute right-5 w-5 h-5 md:w-7 md:h-7 cursor-pointer'
            />
          </div>
        )}
        <div className={`${scroll ? 'bg-themeBlack' : 'bg-transparent'} transition-all duration-300 ease-in-out`}>
          <div className='header px-4 2xl:px-12 py-6'>
            <Link to='/'>
              <img className='logo relative z-10' alt='' src='/images/header/logo.svg' />
            </Link>
            <ul className='navigation absolute z-20 justify-center hidden xl:flex items-center w-[calc(100vw-120px)]'>
              {links.map((item, idx) => {
                return <Menu item={item} key={`main-${idx}`} idx={idx} />
              })}
            </ul>
            <OutsideClickHandler
              onOutsideClick={() => {
                setSecondDrop(false)
              }}
            >
              <div
                onClick={() => {
                  dropDownhandler()
                }}
                className={`${
                  connector ? 'px-2.5 tracking-[1.12px] max-w-[209px] w-full' : 'px-3.5 text-xs tracking-[2px] xl:px-[25px] xl:tracking-[2px]'
                } h-[46.85px] items-center font-semibold text-white  text-sm md:text-sm relative z-20  xl:text-base leading-7  connect-wallet f-f-fg hidden xl:flex`}
              >
                {connector ? (
                  <div className='flex items-center space-x-4  xl:space-x-5'>
                    <div className='flex items-center flex-shrink-0 space-x-2'>
                      <img src={connector.title == 'MetaMask' ? '/images/header/metamask.svg' : connector.logo} className='max-w-[24px] h-6 ' alt='' />
                      <p className=''>{account ? `${account.slice(0, 6)}...${account.slice(-4)}` : ''}</p>
                    </div>
                    <button className={`${secondDrop ? ' rotate-180' : ' rotate-0'} transition-all duration-300 ease-in-out transform w-full`}>
                      <img className='w-4 h-4 flex-shrink-0 xl:w-auto xl:h-auto' src='/images/header/chevron.svg' alt='' />
                    </button>
                  </div>
                ) : (
                  'CONNECT WALLET'
                )}
              </div>

              {secondDrop && (
                <div className='absolute max-w-[209px] w-full py-[15px] px-5 border-themeOrange border hover:border-themeWhite rounded-[3px] top-[60px] mt-5 hidden xl:block z-[101]'>
                  <button onClick={onDisconnect} className='flex items-center space-x-[5.73px]'>
                    <img className='max-w-[24px] h-6' alt='' src='/images/header/logout-icon.svg' />
                    <p className='flex-shrink-0 text-[15px] text-white'>Logout</p>
                  </button>
                </div>
              )}
            </OutsideClickHandler>
            <button
              onClick={() => {
                setOpen(true)
                setSecondDrop(false)
              }}
              className='bg-transparent w-8 xl:hidden'
            >
              <img alt='' src='/images/header/hamburger-menu.png' />
            </button>
          </div>
        </div>
      </div>

      {/* mobile flow */}
      <div className={`top-bg !z-[1000] xl:hidden ${open ? 'top-0' : 'top-minus'}`}>
        <div className='inner-tab'>
          <div className='top-navigation'>
            <Link to='/'>
              <img className='logo-2' alt='' src='/images/header/logo.svg' />
            </Link>
            <div
              onClick={() => {
                setOpen(false)
                closeWalletModal()
              }}
              className='closeButton'
            >
              <img alt='' src='/images/common/close-button1.svg' />
            </div>
          </div>
          <div className='bottom-navigation w-full'>
            <ul className='flex flex-col items-center justify-center'>
              {links.map((item, idx) => {
                return <MobileMenu item={item} idx={idx} route={route} key={`mobile-${idx}`} />
              })}
            </ul>
            {
              <>
                {!connector ? (
                  <button
                    onClick={() => {
                      openWalletModal()
                    }}
                    className='mobile-btn f-f-fg'
                  >
                    <div className='line1' />
                    <div className='line2' />
                    CONNECT WALLET
                  </button>
                ) : (
                  <div className='relative max-w-[230px] flex flex-col items-center justify-center w-full'>
                    <div
                      onClick={() => {
                        setMobileDrop(!mobileDrop)
                      }}
                      className='px-2 py-[9px] tracking-[1.12px] max-w-[200px] w-full mt-5 text-white text-sm leading-7  connect-wallet-2 f-f-fg '
                    >
                      <div className='flex items-center space-x-7'>
                        <div className='flex items-center flex-shrink-0 space-x-2'>
                          <img src={connector.title == 'MetaMask' ? '/images/header/metamask.svg' : connector.logo} className='max-w-[24px] h-6 ' alt='' />
                          <p className='drop-shadow-[0px_0px_4px_#0000004D]'>{account ? `${account.slice(0, 6)}...${account.slice(-4)}` : ''}</p>
                        </div>
                        <button className='w-4 h-4 flex-shrink-0'>
                          <img className='w-full h-full' src='/images/header/chevron.svg' alt='' />
                        </button>
                      </div>
                    </div>
                    {mobileDrop && (
                      <div className='absolute max-w-[200px] w-full py-[11px] px-[8px] border-themeOrange border hover:border-white rounded-[3px] top-[80px]'>
                        <button onClick={onMobileDisconnect} className='flex items-center space-x-[5.73px] w-full'>
                          <img className='max-w-[24px] h-6' alt='' src='/images/header/logout-icon.svg' />
                          <p className='flex-shrink-0 text-sm text-white'>Logout</p>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            }
          </div>
        </div>
        {/* <img className='bg-statue' alt='' src='/images/header/top-bg.png' /> */}
      </div>
      <Transaction />
    </>
  )
}

export default Header
