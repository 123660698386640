export const Tokens = {
  BNB: {
    symbol: 'BNB',
    decimals: 18,
    address: {
      56: '',
      97: '',
    },
  },
}

export const tokensList = {
  weth: {
    symbol: 'WETH',
    address: {
      59140: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
      59144: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  lynx: {
    symbol: 'LYNX',
    address: {
      59140: '0xcFc6834aafaf4bc7279C03CE5C59B3056943f4c7',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  lynxClaim: {
    symbol: 'LYNXClaim',
    address: {
      59140: '',
      59144: '0xF86381C8c4BBF3D398571e3D904EC04f12ac645A',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  ldo: {
    symbol: 'ldo',
    address: {
      59140: '0x567F42De8014399Cd4FD342b886E3377b763767D',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  usdc: {
    symbol: 'usdc',
    address: {
      59140: '',
      59144: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
      97: '',
    },
    decimals: 6,
    projectLink: '#',
  },
  usdt: {
    symbol: 'usdt',
    address: {
      59140: '',
      59144: '0xa219439258ca9da29e9cc4ce5596924745e12b93',
      97: '',
    },
    decimals: 6,
    projectLink: '#',
  },
  wbtc: {
    symbol: 'wbtc',
    address: {
      59140: '',
      59144: '0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4',
      97: '',
    },
    decimals: 8,
    projectLink: '#',
  },
  busd: {
    symbol: 'busd',
    address: {
      59140: '',
      59144: '0x7d43AABC515C356145049227CeE54B608342c0ad',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
  matic: {
    symbol: 'matic',
    address: {
      59140: '',
      59144: '0x265B25e22bcd7f10a5bD6E6410F10537Cc7567e8',
      97: '',
    },
    decimals: 18,
    projectLink: '#',
  },
}
