import React, { useState, useEffect, useContext } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { fetchUserGammaPools } from '../utils/fetchUserPairs'
// import usePrices from '../hooks/usePrices'
import { ZERO_VALUE } from '../utils/formatNumber'
import { Presets } from '../state/mintV3/reducer'
import { PoolTypes } from '../config/constants'
import { useExtraRewardsInfo } from '../hooks/useGeneral'
import { BaseAssetsConetext } from './BaseAssetsConetext'
import { GammaPools } from '../config/constants/gammaPools'
import { ADDRESS_ZERO } from '@uniswap/v3-sdk'

const GammaContext = React.createContext([])

const GammaContextProvider = ({ children }) => {
  const [fusions, setFusions] = useState([])
  const { account } = useWeb3React()
  const extraRewardsInfo = useExtraRewardsInfo()
  const assets = useContext(BaseAssetsConetext)

  useEffect(() => {
    const getUserData = async () => {
      try {
        const gammaPools = GammaPools
        if (gammaPools.length > 0) {
          let userInfos = []
          userInfos = await fetchUserGammaPools(account || ADDRESS_ZERO, gammaPools)

          const userInfo = gammaPools.map((pool) => {
            let kind
            if (['narrow', 'wide'].includes(pool.type.toLowerCase())) {
              kind = PoolTypes.FUSION
            } else {
              kind = PoolTypes.STABLE
            }
            const asset0 = assets.find((ele) => ele.address.toLowerCase() === pool.token0.address.toLowerCase())
            const asset1 = assets.find((ele) => ele.address.toLowerCase() === pool.token1.address.toLowerCase())
            const token0 = {
              address: asset0?.address || pool.token0.address,
              symbol: asset0?.symbol || 'UNKNOWN',
              decimals: asset0?.decimals || 18,
              logoURI: asset0?.logoURI || 'https://thena.fi/images/tokens/UKNOWN.png',
              price: asset0?.price || 0,
            }
            const token1 = {
              address: asset1?.address || pool.token1.address,
              symbol: asset1?.symbol || 'UNKNOWN',
              decimals: asset1?.decimals || 18,
              logoURI: asset1?.logoURI || 'https://thena.fi/images/tokens/UKNOWN.png',
              price: asset1?.price || 0,
            }
            const found = userInfos.find((item) => item.address.toLowerCase() === pool.address.toLowerCase())
            let user = {
              lpBalance: ZERO_VALUE,
              gaugeBalance: ZERO_VALUE,
              gaugeEarned: ZERO_VALUE,
              totalLp: ZERO_VALUE,
              token0claimable: ZERO_VALUE,
              token1claimable: ZERO_VALUE,
              staked0: ZERO_VALUE,
              staked1: ZERO_VALUE,
              stakedUsd: ZERO_VALUE,
              earnedUsd: ZERO_VALUE,
              total0: ZERO_VALUE,
              total1: ZERO_VALUE,
              totalUsd: ZERO_VALUE,
            }
            let extraRewards = {}
            let totalTvl = new BigNumber(0)
            let lpPrice = new BigNumber(0)
            const foundExtra = extraRewardsInfo.find((ele) => ele.pairAddress === pool.address)
            if (foundExtra) {
              extraRewards = {
                amount: foundExtra['pendingReward'],
                symbol: foundExtra.doubleRewarderSymbol,
              }
            }
            if (found) {
              const token0Reserve = new BigNumber(found.token0Reserve)
              const token1Reserve = new BigNumber(found.token1Reserve)
              if (token0.price > 0 && token1.price > 0) {
                totalTvl = token0Reserve.times(token0.price).plus(token1Reserve.times(token1.price))
              } else if (token0.price > 0) {
                totalTvl = token0Reserve.times(token0.price).times(2)
              } else if (token1.price > 0) {
                totalTvl = token1Reserve.times(token1.price).times(2)
              } else {
                totalTvl = new BigNumber(0)
              }
              lpPrice = found.totalSupply > 0 ? totalTvl.div(found.totalSupply) : new BigNumber(0)
              user = {
                ...found,
                staked0: ZERO_VALUE,
                staked1: ZERO_VALUE,
                stakedUsd: ZERO_VALUE,
                earnedUsd: ZERO_VALUE,
                total0: found.totalSupply ? found.totalLp.times(found.token0Reserve).div(found.totalSupply) : ZERO_VALUE,
                total1: found.totalSupply ? found.totalLp.times(found.token1Reserve).div(found.totalSupply) : ZERO_VALUE,
                totalUsd: found.totalLp.times(lpPrice),
                extraRewards,
              }
            }
            return {
              ...pool,
              stable: pool.type === 'Stable',
              title: Presets[`GAMMA_${pool.type.toUpperCase()}`],
              kind,
              tvl: totalTvl,
              lpPrice,
              token0: {
                ...token0,
                reserve: new BigNumber(pool.token0.reserve),
              },
              token1: {
                ...token1,
                reserve: new BigNumber(pool.token1.reserve),
              },
              // TODO: Revisit this
              isValid: true, // fusion.address === '0x5929dbbc11711d2b9e9ca0752393c70de74261f5' ? false : fusion.isValid,
              account: user,
            }
          })
          setFusions(userInfo)
        }
      } catch (e) {
        console.error('user fusions fetched had error', e)
      }
    }
    if (assets.length > 0) {
      getUserData()
    }
  }, [account, assets])

  return <GammaContext.Provider value={fusions}>{children}</GammaContext.Provider>
}

export { GammaContext as GammaContext, GammaContextProvider as GammaContextProvider }
