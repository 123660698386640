import React from 'react'
import Hero from '../../components/pages/home/hero'
// import Box from '../../components/pages/home/box/box'
// import Details from '../../components/pages/home/details'
import Statue from '../../components/pages/home/statue'
import How from '../../components/pages/home/how'
// import Diagram from '../../components/pages/home/diagram'
// import Team from '../../components/pages/home/team'
import Start from '../../components/pages/home/start'
// import Roadmap from '../../components/pages/home/roadmap'
import './home.scss'

const Home = () => {
  return (
    <>
      <Hero />
      {/* <Box /> */}
      <Statue />
      <How />
      {/* <Details /> */}
      {/* <Diagram />
      <Roadmap />
      <Team /> */}
      <Start />
    </>
  )
}

export default Home
