import React, { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Sticky from 'react-stickynode'
import moment from 'moment'
import TransparentButton from '../../components/common/Buttons/transparentButton'
import SearchInput from '../../components/common/Input/SearchInput'
import { formatAmount } from '../../utils/formatNumber'
import { veTHEsContext } from '../../context/veTHEsConetext'
import CreateModal from './createModal'
import ManageModal from './manageModal'
import { useWeb3React } from '@web3-react/core'
import useWalletModal from '../../hooks/useWalletModal'
import { useWithdraw } from '../../hooks/useLock'
import { useTHEAsset } from '../../hooks/useGeneral'
import NoFound from '../../components/common/NoFound'
import usePrices from '../../hooks/usePrices'
// import ReactTooltip from 'react-tooltip'

const Lock = () => {
  const [searchText, setSearchText] = useState('')
  const [selectedItem, setSelectedItem] = useState(null)
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isManageOpen, setIsManageOpen] = useState(false)
  const veTHEs = useContext(veTHEsContext)
  const { account } = useWeb3React()
  const { openWalletModal } = useWalletModal()
  const { onWithdraw, pending } = useWithdraw()
  const prices = usePrices()
  const theAsset = useTHEAsset()
  const navigate = useNavigate()
  // const [arrow, setArrow] = useState(false)

  const renderButton = () => {
    if (account) {
      return (
        <div className='flex items-center space-x-3 '>
          <img src='/images/lock/lock-icon.svg' alt='' />
          <span>CREATE LOCK</span>
        </div>
      )
    } else {
      return <span>CONNECT WALLET</span>
    }
  }

  const filteredData = useMemo(() => {
    return !searchText ? veTHEs : veTHEs.filter((item) => item.id.toString().includes(searchText))
  }, [searchText, veTHEs])

  return (
    <>
      <div className='max-w-[1200px] min-h-[450px] px-5 sm:px-16 md:px-28 mdLg:px-40 lg:px-5 xl:px-0 pt-20  md:pt-[120px] mx-auto'>
        <div className='lg:flex justify-between items-center'>
          <div className='max-w-[532px] w-full'>
            <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Lock</h1>

            <p className='text-[#b8b6cb] text-base md:text-lg leading-[22px] md:leading-6 mt-1'>
              Lock LYNX into veLYNX to earn and govern. Vote with veLYNX to earn bribes and trading fees. veLYNX can be transferred, merged and split. You can
              hold multiple positions.{' '}
              <a href='https://lynex.gitbook.io/lynex/' target='_blank' rel='noreferrer'>
                <span className='!text-lg text-themeOrange'>Learn More</span>
              </a>
            </p>
          </div>
          {/* <div className='mt-3 lg:mt-0 bg-white w-full lg:max-w-[220px]  bg-opacity-[0.05]  rounded-[3px] bg-clip-padding px-5 py-3.5 text-white'>
            <div className='w-full'>
              <p className='f-f-fg text-sm leading-4 xl:text-base xl:leading-5'>veTHE Total APR</p>
              <div
                onMouseEnter={() => {
                  setArrow(true)
                }}
                onMouseLeave={() => {
                  setArrow(false)
                }}
                data-tip
                data-for={`tip`}
                className='flex items-center space-x-1 cursor-pointer max-w-[68px]'
              >
                <span className='text-lg lg:text-2xl xl:text-[27px] leading-5 lg:leading-8'>99%</span>
                <img
                  className={`${arrow ? 'rotate-180' : 'rotate-0'} transition-all duration-300 ease-in-out`}
                  alt=''
                  src='/images/liquidity/small-arrow-bottom.svg'
                />
                <ReactTooltip
                  className='max-w-[180px] !bg-[#090333] !border !border-blue !text-[#E6E6E6] !text-base !py-[9px] !px-6 !opacity-100 after:!bg-body '
                  id={`tip`}
                  place='right'
                  effect='solid'
                >
                  <p> THE</p>
                </ReactTooltip>
              </div>
            </div>
          </div> */}
          <div className='mt-3 xl:mt-0 w-full flex-col-reverse flex lg:flex-row items-center lg:justify-end lg:space-y-0 lg:space-x-3'>
            <SearchInput searchText={searchText} setSearchText={setSearchText} placeholder='Search veLYNX ID' onlyNumber={true} />
            <TransparentButton
              content={renderButton()}
              fontWeight={'font-medium'}
              className={
                'h-[52px] px-7 mb-3 lg:mb-0 text-white flex flex-col items-center justify-center w-full lg:w-auto text-sm xl:text-[17px] tracking-[1.04px] xl:tracking-[1.36px] font-semibold'
              }
              onClickHandler={() => {
                if (account) {
                  setIsCreateOpen(true)
                } else {
                  openWalletModal()
                }
              }}
            />
          </div>
        </div>
        {filteredData && filteredData.length > 0 ? (
          <div className='w-full mt-[14px] lg:mt-5'>
            <Sticky
              enabled={true}
              innerActiveClass={'gradientBorder'}
              top={95}
              activeClass={''}
              innerClass={'px-6  lg:flex justify-between hidden z-[100] py-[0.475rem] lg:!-mb-[19px] xl:!mb-0 lg:!top-[-19px] xl:!top-[0]'}
              className={`z-[100]`}
            >
              <div className='w-[15%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>veLYNX ID</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Lock Value</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Locked Amount</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Lock Expire</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Votes Used</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
            </Sticky>
            <div className='flex flex-col'>
              {filteredData &&
                filteredData.map((item, index) => {
                  return (
                    <div key={`velynx-${index}`} className='gradient-bg p-px space-y-2.5 lg:space-y-0 mt-4 first:mt-0 rounded-[3px]'>
                      <div className='px-4 py-3 lg:p-6 flex flex-col lg:flex-row justify-between items-center rounded-[3px] bg-themeBlack from-left to-right'>
                        <div className='w-full lg:w-[15%] mt-3 lg:mt-0 text-[#DEDBF2] f-f-fg'>
                          <div className='lg:hidden text-[13px] font-semibold'>veLYNX ID</div>
                          <div className='text-lg lg:text-xl font-medium'>{item.id}</div>
                        </div>
                        <div className='w-full lg:w-[34%] flex mt-3 lg:mt-0 text-[#DEDBF2]'>
                          <div className='w-1/2'>
                            <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Lock Value</div>
                            <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(item.voting_amount)} veLYNX</div>
                            <div className='text-[15px] text-dimGray'>${formatAmount(item.voting_amount.times(prices['THE']))}</div>
                          </div>
                          <div className='w-1/2'>
                            <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Locked Amount</div>
                            <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(item.amount)} LYNX</div>
                            <div className='text-[15px] text-dimGray'>${formatAmount(item.amount.times(prices['THE']))}</div>
                          </div>
                        </div>
                        <div className='w-full lg:w-[34%] flex lg:items-center mt-3 lg:mt-0 text-[#DEDBF2]'>
                          <div className='w-1/2'>
                            <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Lock Expire</div>
                            <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] text-[#DEDBF2]'>
                              {moment.unix(item.lockEnd).format('MMM DD, YYYY')}
                            </div>
                            <div className='text-[15px] text-dimGray'>{item.diffDates}</div>
                          </div>
                          <div className='w-1/2 text-[#DEDBF2]'>
                            <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Votes Used</div>
                            <div
                              className={`text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] f-f-fg font-semibold ${
                                item.votedCurrentEpoch ? `text-success` : 'text-error'
                              }`}
                            >
                              {item.votedCurrentEpoch ? 'Yes' : 'No'}
                            </div>
                          </div>
                        </div>
                        <div className='w-full lg:w-[17%] flex lg:justify-end space-x-[14.5px] mt-3 mb-2 lg:mb-0 lg:mt-0'>
                          {item.voting_amount.isZero() ? (
                            <div
                              className='text-base text-green cursor-pointer'
                              onClick={() => {
                                if (!pending) {
                                  onWithdraw(item)
                                }
                              }}
                            >
                              Withdraw
                            </div>
                          ) : (
                            <>
                              <TransparentButton
                                onClickHandler={() => {
                                  navigate(`/vote/${item.id}`)
                                }}
                                content={'Vote'}
                                className={
                                  'h-10 px-4 lg:px-[1.5rem] lg:w-auto w-full text-white flex  whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[17px]'
                                }
                              />
                              <TransparentButton
                                onClickHandler={() => {
                                  setSelectedItem(item)
                                  setIsManageOpen(true)
                                }}
                                content={'Manage'}
                                className={
                                  'h-10 px-4 lg:w-auto w-full text-white flex  whitespace-nowrap flex-col items-center justify-center tex-sm xl:text-[17px]'
                                }
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        ) : (
          <div className='w-full mt-[14px] lg:mt-5'>
            <NoFound title={account ? 'No positions found' : 'Please connect your wallet'} />
          </div>
        )}
      </div>
      {isCreateOpen && <CreateModal isOpen={isCreateOpen} setIsOpen={setIsCreateOpen} theAsset={theAsset} />}
      {isManageOpen && <ManageModal isOpen={isManageOpen} setIsOpen={setIsManageOpen} selected={selectedItem} theAsset={theAsset} />}
    </>
  )
}

export default Lock
