import React from 'react'
import { useLocation } from 'react-router-dom'
import { FaSquareTwitter, FaDiscord, FaTelegram } from 'react-icons/fa6'
import { AiFillMediumSquare } from 'react-icons/ai'

import './style.scss'

const Footer = () => {
  const route = useLocation()
  const iconClasses = 'max-w-[36px] text-themeOrange text-[36px]'
  const links = [
    {
      img: '/images/footer/twitter.png',
      icon: <FaSquareTwitter className={iconClasses} />,
      url: 'https://twitter.com/LynexFi',
    },
    {
      img: '/images/footer/medium.png',
      icon: <AiFillMediumSquare className={iconClasses} />,
      url: 'https://medium.com/@lynexfi',
    },
    {
      img: '/images/footer/discord.png',
      icon: <FaDiscord className={iconClasses} />,
      url: 'https://discord.gg/3BFkJjPmB3',
    },
    {
      img: '/images/footer/telegram.png',
      icon: <FaTelegram className={iconClasses} />,
      url: 'https://t.me/lynexfi',
    },
  ]
  return (
    <div id='footer' className={`footer-wrap relative ${route.pathname.includes('referral') ? 'hidden' : 'block'} lg:block`}>
      {/* <img alt='' src='/images/footer/bg-footer.png' className='w-full bg-index desktop-1 absolute bottom-0' />
      <img alt='' src='/images/footer/bg-footer3.png' className='bg-index mobile-1 absolute bottom-0 w-full' /> */}
      <div className={`mx-auto container-1 w-full relative lg:block`}>
        <div className='w-full content-wrapper-footer'>
          <div className='links-wrapper'>
            <p>Copyright © 2023 Lynex. All rights reserved.</p>
            <div className='flex items-center space-x-[14px]'>
              {links.map((item, idx) => {
                return (
                  <a key={idx} href={item.url} rel='noreferrer' target={'_blank'}>
                    {item.icon ? item.icon : <img alt='' className='max-w-[36px]' src={item.img} />}
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
