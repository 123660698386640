import React from 'react'
import HomePara from '../components/HomePara'
import './style.scss'

const links = [
  {
    label: 'Swap Now',
    value: '/swap',
    external: false,
  },
  {
    label: 'Start Staking',
    value: '/liquidity-info',
    external: false,
  },
  // {
  //   label: 'Go Vote',
  //   value: '/vote',
  //   external: false,
  // },
]

const Statue = () => {
  return (
    <div className='statue-section'>
      <div className='relative'>
        <HomePara
          reverse={true}
          src={'/images/home/home-swap.png'}
          title='Swap Your Tokens'
          span='With Low Slippage'
          para={`Lynex's smart routing, deep liquidity, and latest AMM technology allow you to enjoy low slippage and high return when swapping one cryptocurrency for another.`}
          link={links[0]}
        />

        {/* <div className={'orangeGlow'} /> */}
      </div>
      <HomePara
        reverse={false}
        src={'/images/home/home-stake.png'}
        title='Stake and Earn'
        span='Passive Income'
        para={`Stake your assets for instant passive income streams. No deposit or withdrawal fees. You have full control over your funds.`}
        link={links[1]}
        small
      />
      <div className='relative overflow-x-clip'>
        <HomePara
          reverse={true}
          src={'/images/home/home-liq.png'}
          title='Liquidity Layer'
          span='for Linea blockchain'
          para={`Lynex was designed to onboard the next generation of protocols to the Linea blockchain by opening up a free market for LYNX emissions. Protocols can bribe veLYNX holders or acquire veLYNX to redirect emissions to their pools, offering a flexible and capital efficient solution to bootstrap and scale liquidity.`}
          link={links[2]}
          special
        />

        {/* <div className={'whiteGlow'} /> */}
      </div>
    </div>
  )
}

export default Statue
