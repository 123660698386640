import React, { useMemo } from 'react'
import { useCurrencyBalance } from '../../../../../../hooks/v3/useCurrencyBalances'
import { WETH_EXTENDED, v3LiquidityRangeType } from '../../../../../../v3lib/entities/constants'
import { formatAmount } from '../../../../../../utils/formatNumber'
import { useCurrency } from '../../../../../../hooks/v3/Tokens'
import { useCurrencyLogo } from '../../../../../../hooks/v3/useCurrencyLogo'
import { defaultChainId } from '../../../../../../config/constants'

const percentArray = ['25', '50', '75', '100']
const chainId = defaultChainId

export const TokenAmountCard = ({ currency, value, maxAmount, handleInput, locked, liquidityRangeType = v3LiquidityRangeType.MANUAL_RANGE }) => {
  const eth = useCurrency('ETH')
  const balance = useCurrencyBalance(currency)
  const ethBalance = useCurrencyBalance(eth)
  const wEthBalance = useCurrencyBalance(WETH_EXTENDED[chainId])
  const logoURI = useCurrencyLogo(currency)

  const isDouble = useMemo(() => {
    return (
      liquidityRangeType === v3LiquidityRangeType.GAMMA_RANGE &&
      chainId &&
      currency?.wrapped.address.toLowerCase() === WETH_EXTENDED[chainId].address.toLowerCase()
    )
  }, [liquidityRangeType, currency])

  const balanceString = useMemo(() => {
    if (!balance) return 'Loading'

    if (isDouble) {
      return ((wEthBalance ? Number(wEthBalance.toExact()) : 0) + (ethBalance ? Number(ethBalance.toExact()) : 0)).toFixed(5)
    } else {
      return balance.toSignificant()
    }
  }, [balance, isDouble, wEthBalance, ethBalance])

  return (
    <>
      <div className='w-full'>
        {locked ? (
          <div className='bg-white bg-opacity-[0.05] flex flex-col justify-center items-center rounded-[3px] bg-clip-padding px-5 py-4 text-white text-sm lg:text-base'>
            <img src='/images/lock/lock-icon.svg' alt='' />
            <p className='span mt-1'>Price is outside specified price range.</p>
            <p className='span'>Single-asset deposit only.</p>
          </div>
        ) : (
          <>
            <div className='flex items-center justify-between w-full'>
              <div className={`flex items-center text-sm lg:text-base space-x-3 justify-between w-full`}>
                <div className='flex items-center space-x-2.5'>
                  {percentArray.map((percent, index) => {
                    return (
                      <div
                        className={`flex items-center justify-center bg-white bg-opacity-[0.08] round-[3px] text-white text-[13px] md:text-base w-[40px] md:w-[56px] h-[22px] md:h-[28px] cursor-pointer`}
                        onClick={() => {
                          handleInput(maxAmount?.divide('100')?.multiply(percent)?.toExact() ?? '')
                        }}
                        key={`percent-${index}`}
                      >
                        {percent}%
                      </div>
                    )
                  })}
                </div>
                <p className='text-white'>Balance: {formatAmount(balanceString)}</p>
              </div>
            </div>
            <div className='gradient-bg mt-1.5 lg:mt-2.5  p-px w-full rounded-[3px]'>
              <div className='bg-body px-3  rounded-[3px] flex items-center justify-between'>
                <input
                  className={`bg-transparent ${
                    isDouble ? 'w-[35%] md:w-[50%]' : 'w-[80%]'
                  } py-[8px] lg:py-[15px] text-xl lg:text-2xl leading-10 placeholder-[#757384] text-themeBlack`}
                  value={value}
                  disabled={locked}
                  onChange={(e) => {
                    handleInput(Number(e.target.value) < 0 ? '' : e.target.value)
                  }}
                  placeholder='0.00'
                  type={'number'}
                  min={0}
                />
                {currency && (
                  <div className='flex items-center justify-between space-x-2'>
                    {isDouble ? (
                      <div className='flex items-center -space-x-2'>
                        <img className='relative z-10' width={28} height={28} alt='' src='https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png' />
                        <img className='relative z-[5]' width={28} height={28} alt='' src='https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png' />
                      </div>
                    ) : (
                      <img alt='' width={28} height={28} src={logoURI} />
                    )}
                    <p className='font-medium text-sm lg:text-[1.2rem] leading-6 text-themeBlack'>{isDouble ? 'ETH + WETH' : currency.symbol}</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}
