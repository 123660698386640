const contracts = {
  THE: {
    56: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
    97: '0x4E5009a313978EFa9732997e93928529F8a84072',
    59140: '0xcFc6834aafaf4bc7279C03CE5C59B3056943f4c7',
  },
  WBNB: {
    59140: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
    59144: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
  },
  ETH: {
    56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    97: '',
    59140: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
    59144: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
  },
  thenian: {
    56: '0x2Af749593978CB79Ed11B9959cD82FD128BA4f8d',
    97: '0x45c85db14Ff99803b59989e91178847e1892Ea02',
    59140: '',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    59140: '0xca11bde05977b3631167028862be2a173976ca11',
    59144: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  router: {
    // 56: '0x20a304a7d126758dfe6B243D0fc515F83bCA8431',
    // 56: '0x9B237893321b2D447E1D1Ae02004ebA30b187D0d',
    56: '0xd4ae6eCA985340Dd434D38F470aCCce4DC78D109',
    97: '0x761636F40307AAEC44367BE82FCD9F75FeA310f5',
    59140: '0x3741B49ea971aF693cDa26f83e2206b638a5A5D4',
  },
  factory: {
    56: '0xAFD89d21BdB66d00817d4153E055830B1c2B3970',
    97: '0x86D22c4130401a5723fFD6C0c798c205A2Fea1ed',
    59140: '0xDEAa1c4d0444615Fa908B19183F8AeC7921cCe75',
  },
  veTHE: {
    56: '0xfBBF371C9B0B994EebFcC977CEf603F7f31c070D',
    97: '0x04106fb6E161D0C5E8E0129F4a8a2cd62dCF6110',
    59140: '0x1ED39C6450C4A679D02DEd2A8E7C5f9C775899a6',
  },
  veDist: {
    56: '0xC6bE40f6a14D4C2F3AAdf9b02294b003e3967779',
    97: '0x934547fee052323F05454c8a2d8b45BBc47812D7',
    59140: '0x46F0B257731c6a8Bf10A37E2AEc260c2f4AEFCFA',
  },
  voter: {
    56: '0x62Ee96e6365ab515Ec647C065c2707d1122d7b26',
    97: '0x1344244b3432846d00e1D5cc9FEB825743304539',
    59140: '0x5Ff3C0f8b2ccBC6Eab7a4832c3C01EfaAAA6Df2C',
  },
  v3voter: {
    56: '0x3A1D0952809F4948d15EBCe8d345962A282C4fCb',
    97: '',
    59140: '0x5Ff3C0f8b2ccBC6Eab7a4832c3C01EfaAAA6Df2C',
  },
  minter: {
    56: '0x86069FEb223EE303085a1A505892c9D4BdBEE996',
    97: '',
    59140: '0x6836302bcdA43D6634d9dE4711147e839a523Fb1',
  },
  pairAPI: {
    56: '0x7419477C03b0FEb9286F216b9d19E42f86B288b3',
    97: '',
    59140: '0x217908A76A86Fff7cE1C6A0CB4564Ca24B2e4325',
  },
  veTHEAPI: {
    56: '0x949d5B4eb1A8fbA23AE7741d02C602bFFFdF813b',
    97: '',
    59140: '0x12C3809AF7591f8fC0Ae3b2a44DC6e8282d73A0d',
  },
  rewardsAPI: {
    56: '0x54a3E5ef4Ba0308FC60a5c159a96376cc0bB4787',
    97: '',
    59140: '0x5E37FBC33FC0Ce6cBD36c1c23E4E2049ceF72b75',
  },
  pairV3API: {
    56: '0xE89080cEb6CAEb9Eba5a0d4Aa13686eFcB78A32E',
    97: '',
    59140: '0x217908A76A86Fff7cE1C6A0CB4564Ca24B2e4325',
  },
  veTHEV3API: {
    56: '0x64039b95537D2904716D1957dD07061Ab94a4549',
    97: '',
    59140: '0x12C3809AF7591f8fC0Ae3b2a44DC6e8282d73A0d',
  },
  rewardsV3API: {
    56: '0xa636471A76dd3777e5b101DEA2e07B9656c664e9',
    97: '',
    59140: '0x5E37FBC33FC0Ce6cBD36c1c23E4E2049ceF72b75',
  },
  staking: {
    56: '0xe58E64fb76e3C3246C34Ee596fB8Da300b5Adfbb',
    97: '',
    59140: '',
  },
  royalty: {
    56: '0xBB2caf56BF29379e329dFED453cbe60E4d913882',
    97: '',
    59140: '',
  },
  dibs: {
    56: '0x664cE330511653cB2744b8eD50DbA31C6c4C08ca',
    97: '',
    59140: '',
  },
  dibsLottery: {
    56: '0x287ed50e4c158dac38e1b7e16c50cd1b2551a300',
    97: '',
    59140: '',
  },
  muon: {
    56: '0xBa079Ad36E48e75b8b37f17aF1Fc285bceB84391',
    97: '',
    59140: '',
  },
  openOcean: {
    56: '0x6352a56caadc4f1e25cd6c75970fa768a3304e64',
    97: '',
    59140: '',
  },
  preFarming: {
    56: '0x6352a56caadc4f1e25cd6c75970fa768a3304e64',
    97: '',
    59140: '0x402ce3B30fcF47f88654c58B2f31C6eE405fb6C6',
    59144: '0x0b3a25AE91De4825B52D51CA54DFC8867367c72A',
  },
  preFarmingP2: {
    59144: '0xbf05db69176E47Bf89A6b19F7492d50751D20452',
  },
  pairApiSimple: {
    59144: '0x1c071101d0720B10cCd6412FE479e91bC252BFc1',
  },
}
export default contracts
