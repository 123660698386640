import { ConnectorNames } from '../../utils/connectors'

const connectorLocalStorageKey = 'thena-local-key'
// eslint-disable-next-line no-use-before-define
const defaultChainId = Number(process.env.REACT_APP_CHAIN_ID)

const REACT_APP_MUON_API_URL = 'https://dibs-shield.muon.net/'

const TransactionType = {
  START: 'start',
  WAITING: 'waiting',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
}

const LotteryStatus = {
  UNKNOWN: 0,
  WON: 1,
  LOST: 2,
}

const connectors = [
  {
    key: 'metamask',
    logo: '/images/wallets/metamask-logo.svg',
    title: 'MetaMask',
    connector: ConnectorNames.MetaMask,
  },
  {
    key: 'trustwallet',
    logo: '/images/wallets/trustwallet-logo.svg',
    title: 'Trust Wallet',
    connector: ConnectorNames.TrustWallet,
  },
  {
    key: 'walletConnect',
    logo: '/images/wallets/walletconnect-logo.svg',
    title: 'Wallet Connect',
    connector: ConnectorNames.WalletConnect,
  },
  {
    key: 'coinbase',
    logo: '/images/wallets/coinbase-wallet-logo.svg',
    title: 'Coinbase Wallet',
    connector: ConnectorNames.Coinbase,
  },
  // {
  //   key: 'binance',
  //   logo: '/images/wallets/binance-wallet-logo.svg',
  //   title: 'Binance Wallet',
  //   connector: ConnectorNames.BinanceChainWallet,
  // },
  // {
  //   key: 'coin98',
  //   logo: '/images/wallets/coin98-wallet-logo.svg',
  //   title: 'Coin98 Wallet',
  //   connector: ConnectorNames.Coin98Wallet,
  // },
]

const routeAssets = [
  {
    name: 'Wrapped BNB',
    symbol: 'WBNB',
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/WBNB.png',
  },
  {
    name: 'BUSD Token',
    symbol: 'BUSD',
    address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/BUSD.png',
  },
  {
    name: 'USD Coin',
    symbol: 'USDC',
    address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/USDC.png',
  },
  {
    name: 'Tether USD',
    symbol: 'USDT',
    address: '0x55d398326f99059fF775485246999027B3197955',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/USDT.png',
  },
  {
    name: 'Frax',
    symbol: 'FRAX',
    address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/FRAX.png',
  },
  {
    name: 'Liquid Staking BNB',
    symbol: 'BNBx',
    address: '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/BNBx.png',
  },
  {
    name: 'Coin98 Dollar',
    symbol: 'CUSD',
    address: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/CUSD.png',
  },
  {
    name: 'Hay Destablecoin',
    symbol: 'HAY',
    address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/HAY.png',
  },
  {
    name: 'USD+',
    symbol: 'USD+',
    address: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
    chainId: 56,
    decimals: 6,
    logoURI: 'https://thena.fi/images/tokens/USD+.png',
  },
  {
    name: 'Staked BNB',
    symbol: 'stkBNB',
    address: '0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/stkBNB.png',
  },
  {
    name: 'Ankr Reward Bearing BNB',
    symbol: 'ankrBNB',
    address: '0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/images/tokens/ankrBNB.png',
  },
  {
    name: 'THENA',
    symbol: 'THE',
    address: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
    chainId: 56,
    decimals: 18,
    logoURI: 'https://thena.fi/logo.png',
  },
]
const periodLevels = [
  {
    value: 0,
    label: '2 weeks',
  },
  {
    value: 1,
    label: '6 months',
  },
  {
    value: 2,
    label: '1 year',
  },
  {
    value: 3,
    label: '2 years',
  },
]

const NumberOfRows = [10, 20, 30, 40]

export const PresaleLynxPrice = 0.05

const PoolTypes = {
  ALL: 'ALL',
  STABLE: 'STABLE',
  V1: 'V1',
  FUSION: 'FUSION',
}

const TabSizes = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
}

const ReferralTabs = ['code', 'rewards', 'leaderboard', 'lottery']
const TaxAssets = [
  '0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979', // fBOMB
  '0xc95cd75dcea473a30c8470b232b36ee72ae5dcc2', // CHAM
  '0x3a806a3315e35b3f5f46111adb6e2baf4b14a70d', // LIBERA
  '0x9a7b04fd5788ea39819723e7eb9ef5f609bc57ab', // cpTHE
  '0x5dbcb073bedb36a411b5dd9b23b47ccbb5f7238f', // cpTHENA
]

const NewPools = [
  '0xbf9b34228dc024734793465bc9357ff36caa1ef3',
  '0xb420adb29afd0a4e771739f0a29a4e077eff1acb',
  '0xdf0b9b59e92a2554dedb6f6f4af6918d79dd54c4',
  '0x01dd2d28eeb95d740acb5344b1e2c99b61cc3e64',
  '0xbcbac1c6b935b2d52cc88dc07c6614aac4d06071',
  '0x2e960215441e04baebd941b050194508800c6fea',
  '0xf59ccc4ce79afe0ca576ad7b7e5fa5fb04d3da6f',
  '0x3f18d9a1126e55042da5e4e7c5a9a2f51fcdb48b',
  '0x1c2ac2ea1063395c34d2b5066ebc7f6745196e92',
  '0x8614c284607544ce008a7e7fcdcbc414a274b4b4',
  '0x4fabb616a19669be093103684f4010b5b3351191',
  '0x8664eb4ba0cf0274fc583eceb7ea5bf9b14df0cf',
  '0x99621c478e1e668e9e0714dd1ade5ce60df23427',
  '0xe98d0135d7c8fe39be84a7c5960efa1d15495196',
  '0x10677f99b9056dafc70b5cd74d5482887a060749',
  '0xb6395c71e7fa10334ca1ffb183896772c495d6ea',
  '0xbd5cc2fc7f30fb5d71caba5e5493048fadb6988b',
  '0x96aa798ca64ad5bdf64f086dbd1453982dccd6f4',
  '0x729e04f97026dc7426cf112bbbb00a9472097103',
  '0xa2a57851c9abf123be82a15978b68c0335610808',
  '0x09f3aaac5aa3b07beec5987e09a5623d79cdb548',
]

const STABLE_TOKENS = {
  BUSD: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  USDT: '0x55d398326f99059fF775485246999027B3197955',
  USDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  DAI: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  DEI: '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
  USD: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
  ETS: '0x5B852898CD47d2Be1d77D30377b3642290f5Ec75',
  HAY: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
  FRAX: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  CUSD: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
  MAI: '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
  DOLA: '0x2F29Bc0FFAF9bff337b31CBe6CB5Fb3bf12e5840',
  wUSDR: '0x2952beb1326acCbB5243725bd4Da2fC937BCa087',
}

export const STABLE_FEE = 0.0001
export const VOLATILE_FEE = 0.002

export const TXN_TYPE = {
  ALL: 'All',
  SWAP: 'Swaps',
  ADD: 'Adds',
  REMOVE: 'Removes',
}

export const AnalyticVersions = {
  v1: 'v1',
  fusion: 'fusion',
  total: 'total',
}

export const AnalyticChart = {
  ONE_MONTH_CHART: 1,
  THREE_MONTH_CHART: 2,
  SIX_MONTH_CHART: 3,
  ONE_YEAR_CHART: 4,
  ALL_CHART: 5,
  CHART_COUNT: 60, //limit analytics chart items not more than 60
}

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

export const TRACKED_OVERRIDES_PAIRS = []
export const TRACKED_OVERRIDES_TOKENS = []

export {
  connectorLocalStorageKey,
  defaultChainId,
  connectors,
  TransactionType,
  routeAssets,
  periodLevels,
  NumberOfRows,
  PoolTypes,
  LotteryStatus,
  REACT_APP_MUON_API_URL,
  TaxAssets,
  STABLE_TOKENS,
  ReferralTabs,
  NewPools,
  TabSizes,
}
