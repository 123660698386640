import { useContext, useMemo } from 'react'
import { computePoolAddress } from './computePoolAddress'
import { useToken } from './Tokens'
import { Pool } from '../../v3lib/entities/pool'
import { POOL_DEPLOYER_ADDRESS } from '../../config/constants/v3/addresses'
import { PoolsContext } from '../../context/PoolsContext'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'
import { defaultChainId } from '../../config/constants'
import { useV3Positions } from './useV3Positions'

export const PoolState = {
  LOADING: 'LOADING',
  NOT_EXISTS: 'NOT_EXISTS',
  EXISTS: 'EXISTS',
  INVALID: 'INVALID',
}

export function usePools(poolKeys) {
  const pools = useContext(PoolsContext)
  const chainId = defaultChainId
  const transformed = useMemo(() => {
    return poolKeys.map(([currencyA, currencyB]) => {
      if (!currencyA || !currencyB) return null

      const tokenA = currencyA?.wrapped
      const tokenB = currencyB?.wrapped
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return null
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [token0, token1]
    })
  }, [poolKeys])

  const poolAddresses = useMemo(() => {
    return transformed.map((value) => {
      if (!value) return undefined
      return computePoolAddress({
        poolDeployer: POOL_DEPLOYER_ADDRESS[chainId],
        tokenA: value[0],
        tokenB: value[1],
      })
    })
  }, [chainId, transformed])

  return useMemo(() => {
    return poolAddresses.map((poolAddress, index) => {
      const [token0, token1] = transformed[index] ?? []
      if (!token0 || !token1) return [PoolState.INVALID, null]

      const found = pools.find((ele) => ele.address.toLowerCase() === poolAddress.toLowerCase())
      if (!found) return [PoolState.NOT_EXISTS, null]
      const { globalState, liquidity, tickSpacing } = found
      if (!globalState || !liquidity || !tickSpacing) return [PoolState.NOT_EXISTS, null]
      if (!globalState.price || globalState.price.eq(0)) return [PoolState.NOT_EXISTS, null]
      try {
        return [PoolState.EXISTS, new Pool(token0, token1, globalState.fee, globalState.price, liquidity, globalState.tick, tickSpacing)]
      } catch (error) {
        return [PoolState.NOT_EXISTS, null]
      }
    })
  }, [poolAddresses, transformed, pools])
}

export function useUserPools(account) {
  const positions = useV3Positions(account)
  const pools = useContext(PoolsContext)
  const assets = useContext(BaseAssetsConetext)

  const computedPools = pools.map((pool) => {
    // TODO: use weekly average
    const day = pool.poolDayData[0]
    const dailyFee = parseFloat(day.feesUSD)
    const totalStaked = parseFloat(pool.totalValueLockedUSD)
    const yearlyFee = dailyFee * 365
    const apr = (yearlyFee / totalStaked) * 100
    const token0 = assets.find((item) => item.address.toLowerCase() === pool.token0.id.toLowerCase())
    const token1 = assets.find((item) => item.address.toLowerCase() === pool.token1.id.toLowerCase())

    return {
      ...pool,
      apr,
      poolApr: pool.poolApr,
      tvl: pool.totalValueLockedUSD,
      token0: {
        ...token0,
        tvl: pool.totalValueLockedToken0,
      },
      token1: {
        ...token1,
        tvl: pool.totalValueLockedToken1,
      },
      symbol: `${token1?.symbol}/${token0?.symbol}`,
    }
  })

  return { positions, pools, computedPools }
}

export function usePool(currencyA, currencyB) {
  const poolKeys = useMemo(() => [[currencyA, currencyB]], [currencyA, currencyB])

  return usePools(poolKeys)[0]
}

export function useTokensSymbols(token0, token1) {
  const _token0 = useToken(token0)
  const _token1 = useToken(token1)

  return useMemo(() => [_token0, _token1], [_token0, _token1])
}
