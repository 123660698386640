export const GammaPools = [
  {
    symbol: 'BUSD/WETH Narrow',
    title: 'GAMMA_NARROW',
    type: 'Narrow',
    address: '0x6e9d701fb6478ed5972a37886c2ba6c82a4cbb4c',
    token0: {
      address: '0x7d43AABC515C356145049227CeE54B608342c0ad',
    },
    token1: {
      address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
    },
  },
  {
    symbol: 'WBTC/WETH Narrow',
    title: 'GAMMA_NARROW',
    type: 'Narrow',
    address: '0x8a9570ec97534277ade6e46d100939fbce4968f0',
    token0: {
      address: '0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4',
    },
    token1: {
      address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
    },
  },
  {
    symbol: 'USDC/BUSD Stable',
    title: 'GAMMA_STABLE',
    type: 'Stable',
    address: '0xd6cc4a33da7557a629e819c68fb805ddb225f517',
    token0: {
      address: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
    },
    token1: {
      address: '0x7d43AABC515C356145049227CeE54B608342c0ad',
    },
  },
  {
    symbol: 'BUSD/USDT Stable',
    title: 'GAMMA_STABLE',
    type: 'Stable',
    address: '0x32e27ff479454e32868ff67ee9f06bafdc1e908f',
    token0: {
      address: '0x7d43AABC515C356145049227CeE54B608342c0ad',
    },
    token1: {
      address: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
    },
  },
  {
    symbol: 'USDT/WETH Narrow',
    title: 'GAMMA_NARROW',
    type: 'Narrow',
    address: '0xf3b1125c8505f038503e002e61a78253610d4f60',
    token0: {
      address: '0xa219439258ca9da29e9cc4ce5596924745e12b93',
    },
    token1: {
      address: '0xe5d7c2a44ffddf6b295a15c148167daaaf5cf34f',
    },
  },
  {
    symbol: 'USDC/WETH Narrow',
    title: 'GAMMA_NARROW',
    type: 'Narrow',
    address: '0x0b15a5e3ca0d4b492c3b476d0f807535f9b72079',
    token0: {
      address: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
    },
    token1: {
      address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
    },
  },
  {
    symbol: 'MATIC/WETH Narrow',
    title: 'GAMMA_NARROW',
    type: 'Narrow',
    address: '0x8421c6102ee8a147facc01977df3b159f7921d54',
    token0: {
      address: '0x265B25e22bcd7f10a5bD6E6410F10537Cc7567e8',
    },
    token1: {
      address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
    },
  },
]
