import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import Settings from '../../../../common/Settings'
import PairsDropDown from '../../../../common/PairsDropDown'
import StyledButton from '../../../../common/Buttons/styledButton'
import { BaseAssetsConetext } from '../../../../../context/BaseAssetsConetext'
import { useCurrency } from '../../../../../hooks/v3/Tokens'
import { useV3DerivedMintInfo, useV3MintActionHandlers, useV3MintState } from '../../../../../state/mintV3/hooks'
import { v3LiquidityRangeType } from '../../../../../v3lib/entities/constants'
import InitialPrice from './containers/InitialPrice'
import SelectRange from './containers/SelectRange'
import { EnterAmounts } from './containers/EnterAmounts'
import { AddLiquidityButton } from './containers/AddLiquidityButton'
import useWalletModal from '../../../../../hooks/useWalletModal'
import { Field, setInitialTokenPrice, setInitialUSDPrices, updateSelectedPreset } from '../../../../../state/mintV3/actions'
import { GammaContext } from '../../../../../context/GammaContext'

export const PriceFormats = {
  TOKEN: 'TOKEN',
  USD: 'USD',
}

const feeAmount = 100
const priceFormat = PriceFormats.TOKEN

const V3AddLiquidity = () => {
  const [firstAsset, setFirstAsset] = useState()
  const [secondAsset, setSecondAsset] = useState()
  const [settings, setSettings] = useState(false)
  const [slippage, setSlippage] = useState(0.5)
  const [deadline, setDeadline] = useState(20)
  const navigate = useNavigate()
  const { account } = useWeb3React()
  const baseAssets = useContext(BaseAssetsConetext)
  const baseCurrency = useCurrency(firstAsset ? firstAsset.address : undefined)
  const currencyB = useCurrency(secondAsset ? secondAsset.address : undefined)
  const quoteCurrency = baseCurrency && currencyB && baseCurrency.wrapped.equals(currencyB.wrapped) ? undefined : currencyB
  const mintInfo = useV3DerivedMintInfo(baseCurrency ?? undefined, quoteCurrency ?? undefined, feeAmount, baseCurrency ?? undefined, undefined)
  const { liquidityRangeType } = useV3MintState()
  const { openWalletModal } = useWalletModal()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const gammas = useContext(GammaContext)
  const [init, setInit] = useState(false)
  const currencyAAddress = baseCurrency && baseCurrency.wrapped ? baseCurrency.wrapped.address.toLowerCase() : ''
  const currencyBAddress = quoteCurrency && quoteCurrency.wrapped ? quoteCurrency.wrapped.address.toLowerCase() : ''
  const gammaPairs = useMemo(() => {
    const result = gammas.filter(
      (ele) =>
        (ele.token0.address.toLowerCase() === currencyAAddress && ele.token1.address.toLowerCase() === currencyBAddress) ||
        (ele.token1.address.toLowerCase() === currencyAAddress && ele.token0.address.toLowerCase() === currencyBAddress),
    )
    return result.length > 0 ? result : null
  }, [gammas, currencyAAddress, currencyBAddress])

  useEffect(() => {
    const inputCurrency = searchParams.get('currency0')
    const outputCurrency = searchParams.get('currency1')
    const from = inputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === inputCurrency.toLowerCase()) : null
    const to = outputCurrency ? baseAssets.find((asset) => asset.address.toLowerCase() === outputCurrency.toLowerCase()) : null
    if (!from) {
      if (!firstAsset) {
        setFirstAsset(baseAssets.find((asset) => asset.symbol === 'BNB'))
      }
    } else {
      if (inputCurrency && !init) {
        setFirstAsset(from)
        setInit(true)
      }
    }
    if (!to) {
      if (!secondAsset) {
        setSecondAsset(baseAssets.find((asset) => asset.symbol === 'THE'))
      }
    } else {
      if (outputCurrency && !init) {
        setSecondAsset(to)
        setInit(true)
      }
    }
  }, [baseAssets, searchParams])

  const { onStartPriceInput } = useV3MintActionHandlers(mintInfo.noLiquidity)

  const resetState = useCallback(() => {
    dispatch(updateSelectedPreset({ preset: null }))
    dispatch(setInitialTokenPrice({ typedValue: '' }))
    dispatch(setInitialUSDPrices({ field: Field.CURRENCY_A, typedValue: '' }))
    dispatch(setInitialUSDPrices({ field: Field.CURRENCY_B, typedValue: '' }))
    onStartPriceInput('')
  }, [dispatch, onStartPriceInput])

  const handleCurrencyASelect = useCallback(() => {
    resetState()
  }, [resetState])

  const handleCurrencyBSelect = useCallback(() => {
    resetState()
  }, [resetState])

  return (
    <div className='w-full lg:max-w-[550px] gradient-bg p-px rounded-[5px] relative '>
      <OutsideClickHandler
        onOutsideClick={() => {
          setSettings(false)
        }}
      >
        <div className='w-full popup-gradientbg px-3 py-3 rounded-[5px] md:px-6 md:py-5 h-full'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <button
                className='w-5 md:w-auto mr-1.5 md:mr-5'
                onClick={() => {
                  navigate('/liquidity-info')
                }}
              >
                <img alt='' src='/images/swap/back-arrow.svg' />
              </button>
              <h4 className='text-[1.3rem] md:text-[22px] f-f-fg text-white font-bold'>Supply Liquidity</h4>
            </div>

            <div className='flex items-center space-x-6'>
              <button className='text-themeOrange text-sm md:text-base' onClick={() => resetState()}>
                Clear All
              </button>
              <button
                onClick={() => {
                  setSettings(!settings)
                }}
                className=''
              >
                <img className='w-3/4 sm:w-auto' alt='' src='/images/swap/bar.svg' />
              </button>
            </div>
          </div>
          {account ? (
            <>
              <div className='mt-4 md:mt-6'>
                <p className='text-[13px] md:text-base leading-5 text-[#B8B6CB]'>Select Pair</p>
                <div className='flex items-center mt-[6px] md:mt-2 w-full space-x-[7px] md:space-x-4'>
                  <PairsDropDown
                    asset={firstAsset}
                    setAsset={setFirstAsset}
                    otherAsset={secondAsset}
                    setOtherAsset={setSecondAsset}
                    onAssetSelect={handleCurrencyASelect}
                  />
                  <button
                    className='flex-shrink-0 w-[30px] md:w-auto'
                    onClick={() => {
                      const temp = firstAsset
                      setFirstAsset(secondAsset)
                      setSecondAsset(temp)
                    }}
                  >
                    <img alt='' src='/images/buttons/swap-tokens.png' className='' />
                  </button>
                  <PairsDropDown
                    asset={secondAsset}
                    setAsset={setSecondAsset}
                    otherAsset={firstAsset}
                    setOtherAsset={setFirstAsset}
                    onAssetSelect={handleCurrencyBSelect}
                  />
                </div>
              </div>
              {mintInfo.noLiquidity && baseCurrency && quoteCurrency && liquidityRangeType === v3LiquidityRangeType.MANUAL_RANGE && (
                <InitialPrice currencyA={baseCurrency ?? undefined} currencyB={currencyB ?? undefined} mintInfo={mintInfo} />
              )}
              <SelectRange currencyA={baseCurrency} currencyB={quoteCurrency} mintInfo={mintInfo} priceFormat={priceFormat} gammaPairs={gammaPairs} />
              <EnterAmounts
                currencyA={baseCurrency ?? undefined}
                currencyB={currencyB ?? undefined}
                mintInfo={mintInfo}
                liquidityRangeType={liquidityRangeType}
              />
              <AddLiquidityButton
                baseCurrency={baseCurrency ?? undefined}
                quoteCurrency={quoteCurrency ?? undefined}
                mintInfo={mintInfo}
                slippage={slippage}
                deadline={deadline}
                gammaPairs={gammaPairs}
              />
            </>
          ) : (
            <StyledButton
              onClickHandler={openWalletModal}
              content={'CONNECT WALLET'}
              className='mt-3 py-[13px] md:py-[14.53px] text-white  text-sm md:text-lg leading-4 tracking-[1.12px] md:tracking-[1.44px] flex items-center justify-center  md:leading-[30px] px-[19px] w-full rounded-[3px]'
            />
          )}
        </div>
        <div className='w-full popup-gradientbg px-3   md:px-6 '>
          {settings && <Settings slippage={slippage} setSlippage={setSlippage} deadline={deadline} setDeadline={setDeadline} />}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default V3AddLiquidity
