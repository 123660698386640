import React, { useContext, useMemo } from 'react'
import AnalytcisHeader from '../../components/pages/analytics/analyticsHeader'
import { useAnalyticsVersion } from '../../hooks/useGeneral'
import { useAllPairData } from '../../context/PairData'
import PairsTable from '../../components/pages/analytics/pairsTable'
import { BaseAssetsConetext } from '../../context/BaseAssetsConetext'

const AnalyticsPairs = () => {
  const version = useAnalyticsVersion()
  const pairs = useAllPairData(version)
  const assets = useContext(BaseAssetsConetext)
  const pairsWithImg = useMemo(() => {
    if (Object.keys(pairs).length > 0)
      return Object.values(pairs).map((pair) => {
        const found0 = assets.find((ele) => ele.address.toLowerCase() === pair.token0.id)
        const found1 = assets.find((ele) => ele.address.toLowerCase() === pair.token1.id)
        return {
          ...pair,
          token0: {
            ...pair.token0,
            name: found0 ? found0.name : pair.token0.name,
            symbol: found0 ? found0.symbol : pair.token0.symbol,
            logoURI: found0 ? found0.logoURI : '/images/tokens/UKNOWN.png',
          },
          token1: {
            ...pair.token1,
            name: found1 ? found1.name : pair.token1.name,
            symbol: found1 ? found1.symbol : pair.token1.symbol,
            logoURI: found1 ? found1.logoURI : '/images/tokens/UKNOWN.png',
          },
        }
      })
    return []
  }, [pairs])

  return (
    <>
      <div className='w-full pt-20 md:pt-[120px] pb-28 xl:pb-0 2xl:pb-[150px] px-5 xl:px-0 '>
        <div className='max-w-[1104px] mx-auto w-full'>
          <AnalytcisHeader />
          <p className='text-[27px] leading-8 text-white f-f-fg font-medium mt-10'>All Pairs</p>
          <PairsTable pairsData={pairsWithImg} />
        </div>
      </div>
    </>
  )
}

export default AnalyticsPairs
