import React, { useMemo } from 'react'
// import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TabFilter from '../../../common/TabFilter'
// import SearchInput from '../../../common/Input/SearchInput'
import { useAnalyticsVersion } from '../../../../hooks/useGeneral'

const Tabs = ['OVERVIEW', 'TOKENS', 'PAIRS']

const getSubRoute = (item) => {
  return item === Tabs[0] ? '' : item === Tabs[1] ? '/tokens' : '/pairs'
}

const AnalyticsHeader = ({ data }) => {
  // const [searchText, setSearchText] = useState('')
  const version = useAnalyticsVersion()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeTab = useMemo(() => {
    const arr = pathname.split('/')
    let result
    switch (arr[arr.length - 1]) {
      case 'pairs':
        result = Tabs[2]
        break
      case 'tokens':
        result = Tabs[1]
        break

      default:
        result = Tabs[0]
        break
    }
    return result
  }, [pathname])

  return (
    <>
      <h1 className='text-[34px] md:text-[42px] font-semibold text-white  f-f-fg'>Lynex Analytics</h1>
      <div className='lg:flex items-center justify-between mt-5 lg:mt-11'>
        {data ? (
          <p className='text-[15px] leading-[15px] text-[#B8B6CB]'>
            <span
              className='cursor-pointer'
              onClick={() => {
                navigate(`/analytics/${version}`)
              }}
            >
              Analytics
            </span>
            &nbsp;{'>'}&nbsp;
            <span
              className='cursor-pointer'
              onClick={() => {
                navigate(`/analytics/${version}/${data.token0 ? 'pairs' : 'tokens'}`)
              }}
            >
              {data.token0 ? 'Pairs' : 'Tokens'}
            </span>
            &nbsp;{'>'}&nbsp;
            <span className='text-white font-medium'>{data.token0 ? data.token0.symbol + '/' + data.token1.symbol : data.symbol}</span>&nbsp;
            <span>({data.id && data.id.slice(0, 6) + '...' + data.id.slice(38, 42)})</span>
          </p>
        ) : (
          <TabFilter
            wfull={true}
            data={Tabs}
            filter={activeTab}
            setFilter={(item) => {
              navigate(`/analytics/${version}${getSubRoute(item)}`)
            }}
          />
        )}
        {/* <SearchInput className={'mt-5 lg:mt-0'} searchText={searchText} setSearchText={setSearchText} placeholder='Search Pair or Token' /> */}
      </div>
    </>
  )
}

export default AnalyticsHeader
